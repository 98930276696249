import { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { NAV_ID } from 'constants/app.constant';
import { appActions } from 'redux/ducks/app';
import { ChartWrapper, PageLoader, withHTMLHeadSEO } from 'components/common';
import { Flyout, PageWrapper } from 'components/common/layout';
import 'components/common/layout/with.chart.layout.scss';
import { WithChartLayoutState } from 'components/common/layout/withChartLayout';
import ClinicalSetup from 'components/features/chart/clinical-setup/ClinicalSetup';

interface ClinicalSetupPageProps {
  setSelectedNavId: Function;
}

class ClinicalSetupPage extends Component<ClinicalSetupPageProps, WithChartLayoutState> {
  constructor(props) {
    super(props);
    this.props.setSelectedNavId(NAV_ID.PATIENT);
    this.state = {
      isFlyoutOpen: false,
      flyoutContent: null
    };
  }

  toggleFlyout = (content: JSX.Element | null = null) => {
    this.setState((state) => ({
      ...state,
      flyoutContent: content,
      isFlyoutOpen: !state.isFlyoutOpen
    }));
  };

  render() {
    return (
      <div className="flex-container">
        <PageLoader />
        <div className="chart">
          <PageWrapper showChartingDateTime showPatientRibbon={false} showNavigation={false} showSubmitAssessmentButton={false} toggleFlyout={this.toggleFlyout}>
            {({ loggedInUser }) => (
              <ChartWrapper>
                {({ selectedNavId, assessment, handleNavigationAttempt, loadChartData, saveChartData, displayAuthoringData, isAuthor, enableDisplayRecordsButton }) => (
                  <ClinicalSetup
                    student={loggedInUser}
                    selectedNavId={selectedNavId}
                    handleNavigationAttempt={handleNavigationAttempt}
                    assessment={assessment}
                    saveChartData={saveChartData}
                    loadChartData={loadChartData}
                    displayAuthoringData={displayAuthoringData}
                    isAuthor={isAuthor}
                    enableDisplayRecordsButton={enableDisplayRecordsButton}
                  />
                )}
              </ChartWrapper>
            )}
          </PageWrapper>
        </div>
        <Flyout isOpen={this.state.isFlyoutOpen} content={this.state.flyoutContent} closeFlyout={this.toggleFlyout} />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  setSelectedNavId: (navId) => dispatch(appActions.setSelectedNavId(navId))
});

const enhancers = [withHTMLHeadSEO({ title: 'Clinical Setup' }), connect(null, mapDispatchToProps)];
export { ClinicalSetupPage as BaseClinicalSetupPage };
export default compose(...enhancers)(ClinicalSetupPage);
