import { LanguageKeys } from 'lang';
import { capitalize } from 'lodash';
import moment from 'moment';
import { Component } from 'react';
import { IntlShape, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { AssessmentRS, PatientRecord } from 'models/api-response';
import { FragmentType, UserRole } from 'models/enum';
import { ChartFieldContent, Patient } from 'models/ui';
import { AppConstant, ChartValue, Locales, NAV_ID } from 'constants/app.constant';
import { apiHelper, chartHelper, dateTimeHelper, patientHelper, unitConverter } from 'helpers';
import { getEmptyEhrDemo, isDemoEmptyEhr } from 'helpers/assignment.helper';
import { chartService, featureService, navigationService } from 'services';
import { appActions, appSelectors } from 'redux/ducks/app';
import { studentActions } from 'redux/ducks/student';
import { GeneralOrderType } from 'components/features/chart/order-entry/general-orders/constants';
import PatientRibbonView, { PatientRibbonViewProps } from './PatientRibbonView';

export interface PatientRibbonProps {
  assessment: AssessmentRS;
  patient: Patient;
  setPatient: Function;
  setOrderDescription: Function;
  setSelectedNavId: Function;
  intl: IntlShape;
  locale: Locales;
  userRole: UserRole;
}

export interface PatientRibbonState {
  chartFieldContentSet: ChartFieldContent[];
  isLoading: boolean;
  isShowClinicalUnit: boolean;
}

class PatientRibbon extends Component<PatientRibbonProps, PatientRibbonState> {
  static displayName = 'PatientRibbon';

  constructor(props) {
    super(props);
    this.state = {
      chartFieldContentSet: [],
      isLoading: true,
      isShowClinicalUnit: false
    };
  }

  componentDidMount() {
    const { assessment } = this.props;

    Promise.all([this.loadClinicalSetupMetadata(), this.loadIsShowClinicalUnit()]).catch((err) => apiHelper.showApiError(`can not load clinical setup data ${err}`));

    if (assessment?.simChartId?.length) {
      this.loadRibbonData();
    }
  }

  componentDidUpdate() {
    const { assessment, patient } = this.props;
    const { isLoading } = this.state;

    // if there is no patient data yet and isLoading, load patient data
    if (!patient && isLoading && assessment?.simChartId?.length) {
      this.loadRibbonData();
    }

    if (patient && isLoading) this.setState({ isLoading: false });
  }

  loadIsShowClinicalUnit = () => featureService.isShowClinicalUnit().then((isShowClinicalUnit) => this.setState({ isShowClinicalUnit }));

  loadClinicalSetupMetadata = () =>
    // content of client setup shouldn't depends on itself, so passing null.
    chartService.fetchChartMetadata(NAV_ID.PATIENT, null).then(({ data }) =>
      this.setState({
        chartFieldContentSet: data?.chartFieldContentSet
      })
    );

  loadRibbonData = async () => {
    const emptyEhrDemo = getEmptyEhrDemo();
    const isInDemoEmptyEhr = isDemoEmptyEhr();
    const isDemoEhrNavigated = sessionStorage.getItem(AppConstant.IS_DEMO_EHR_NAVIGATED) === 'true' && this.props.userRole === UserRole.INSTRUCTOR;
    const patientData =
      isInDemoEmptyEhr && isDemoEhrNavigated
        ? []
        : await chartService
            .loadFragments({
              chartId: this.props.assessment.simChartId,
              fragmentTypes: [FragmentType.CHARTING, FragmentType.AUTHORED],
              navIds: [NAV_ID.HEIGHT_WEIGHT, NAV_ID.PATIENT, NAV_ID.ALLERGIES, NAV_ID.NUTRITION, NAV_ID.GENERAL_ORDERS]
            })
            .then(({ data }) => data);
    const patient =
      isInDemoEmptyEhr && isDemoEhrNavigated
        ? emptyEhrDemo?.patientInfo
        : (patientData.find((chartFragment) => chartFragment.navElementId === NAV_ID.PATIENT)?.chartData as PatientRecord);
    const heightWeightRecords = patientData.filter((chartFragment) => chartFragment.navElementId === NAV_ID.HEIGHT_WEIGHT);

    const latestHeightWeightValues = heightWeightRecords?.length ? chartHelper.getLatestHeightWeightValues(heightWeightRecords) : {};

    const allergiesSection = 'Allergies';
    const noKnownAllergiesLatest = patientData
      .filter((fragment) => fragment.navElementId === NAV_ID.ALLERGIES && fragment.active && chartHelper.getFragmentRecords(fragment, allergiesSection, 'noKnownAllergies')[0])
      .sort((firstFragment, secondFragment) => {
        if (moment(firstFragment.createdAt).isAfter(secondFragment.createdAt)) {
          return -1;
        }
        return 1;
      })?.[0];
    const allergyRecords = patientData
      .filter((chartFragment) => {
        const isAfterNoKnownAllergiesLatest = noKnownAllergiesLatest ? moment(chartFragment.createdAt).isAfter(noKnownAllergiesLatest.chartingAt) : true;
        const noKnownAllergies = chartHelper.getFragmentRecords(chartFragment, allergiesSection, 'noKnownAllergies')[0];
        return chartFragment.navElementId === NAV_ID.ALLERGIES && chartFragment.active && !noKnownAllergies && isAfterNoKnownAllergiesLatest;
      })
      .map((fragment) => ({
        allergyType: chartHelper.getFragmentRecords(fragment, allergiesSection, 'allergyType')[0]?.content,
        allergen: chartHelper.getFragmentRecords(fragment, allergiesSection, 'allergen')[0]?.content,
        allergenOther: chartHelper.getFragmentRecords(fragment, allergiesSection, 'allergenOther')[0]?.content,
        medication: chartHelper.getFragmentRecords(fragment, allergiesSection, 'medication')[0]?.content,
        allergenMedicationOther: chartHelper.getFragmentRecords(fragment, allergiesSection, 'allergenMedicationOther')[0]?.content
      }));

    const nutritionRecords = patientData
      .filter((chartFragment) => chartFragment.navElementId === NAV_ID.NUTRITION && chartFragment.active)
      .map((fragment) => ({
        orderDescription: fragment.chartData.orderDescription,
        orderDetail: fragment.chartData.orderDetail
      }));

    const generalOrderRecords = patientHelper.buildGeneralOrderRecords(patientData);

    this.props.setPatient({
      clinicalSetup: { ...patient },
      heightWeightRecord: { ...latestHeightWeightValues },
      allergyRecords,
      nutritionRecords,
      generalOrderRecords
    });
    this.setState({ isLoading: false });
  };

  // eslint-disable-next-line sonarjs/cognitive-complexity
  formattedPatientData = () => {
    const { patient, locale, intl } = this.props;

    if (!patient) {
      return {
        patientIdentifier: '',
        dob: '',
        sex: '',
        gender: '',
        pronouns: '',
        height: '',
        weight: '',
        mrn: '',
        room: '',
        provider: '',
        clinicalUnit: '',
        allergies: []
      };
    }

    // format known patient data
    let dob = '';

    if (patient.clinicalSetup?.patientDateOfBirth) {
      const currentBirthday = dateTimeHelper.formatDate({ date: dateTimeHelper.toMoment(patient.clinicalSetup.patientDateOfBirth).toDate(), locale });
      const caseStudyDateOffset = dateTimeHelper.getDateOffset(dateTimeHelper.toDate(patient.clinicalSetup.clinicalFirstDay), locale);
      const newDateOfBirth = dateTimeHelper.getNewDateOfBirth(currentBirthday, caseStudyDateOffset, locale);
      const age = dateTimeHelper.dateToAgeString({ date: newDateOfBirth, locale });
      dob = `${newDateOfBirth} (Age: ${age})`;
    }

    const sex = capitalize(patient.clinicalSetup?.patientSex);
    const specifyBelow = capitalize(patient.clinicalSetup?.specifyBelow);
    const gender = capitalize(patient.clinicalSetup?.gender);
    const pronouns = capitalize(patient.clinicalSetup?.pronouns);

    // fetch, convert, and format height/weight values
    let height = ChartValue.VALUE_NA;
    let weight = ChartValue.VALUE_NA;
    let weightDateRecorded = '';

    if (patient.heightWeightRecord?.height) {
      let { feet, inches } = patient.heightWeightRecord.height;
      const { centimeters } = patient.heightWeightRecord.height;
      if (!feet && !inches) {
        const convertedHeight = unitConverter.convertHeight('', '', centimeters, false);
        feet = convertedHeight.heightFeet;
        inches = convertedHeight.heightInch;
      }

      const heightUs = unitConverter.getDisplayValue([feet, inches], 'height');
      height = intl.formatMessage({ id: LanguageKeys.HEIGHT_WEIGHT.HEIGHT_DISPLAY }, { feetAndInches: heightUs, centimeters });
    }

    if (patient.heightWeightRecord?.weight) {
      const { weightPound, weightOunce, weightKg, weightGram } = unitConverter.convertWeight('', '', '', patient.heightWeightRecord.weight, false);

      const weightUS = unitConverter.getDisplayValue([weightPound, weightOunce], 'weight');
      const weightMetric = unitConverter.getDisplayValue([weightKg, weightGram], 'weightMetric');

      weight = intl.formatMessage({ id: LanguageKeys.HEIGHT_WEIGHT.WEIGHT_DISPLAY }, { poundsAndOunces: weightUS, kilogramsAndGrams: weightMetric });
      weightDateRecorded = dateTimeHelper.formatDate({ date: dateTimeHelper.toMoment(patient.heightWeightRecord.weightDateRecorded).toDate(), locale });
    }

    const clinicalUnit = patient.clinicalSetup?.clinicalUnit?.choices?.length
      ? chartHelper.findContentFromMetadata(
          this.state.chartFieldContentSet,
          patient.clinicalSetup.clinicalUnit.choices.map((item) => item.value)
        )
      : '';

    // format allergies records
    const allergies = [];
    if (patient.allergyRecords?.length) {
      patient.allergyRecords.forEach((record) => {
        const allergyType = record.allergyType || ChartValue.VALUE_NA;
        const allergen = record.allergen || record.allergenOther || record.medication || record.allergenMedicationOther || ChartValue.VALUE_NA;
        allergies.push(`Type: ${allergyType}, Allergen: ${allergen}`);
      });
    }

    // format nutrition records
    const nutrition = patient.nutritionRecords?.map((record) => `Type: ${record.orderDescription}, Order detail: ${record.orderDetail}`);

    // format general orders records
    const alerts = patient.generalOrderRecords?.filter((record) => record.orderDescription === GeneralOrderType.ALERTS).map((record) => record.orderDetails);
    const codeStatus = patient.generalOrderRecords?.filter((record) => record.orderDescription === GeneralOrderType.CODE_STATUS).map((record) => record.orderDetails);
    const isolation = patient.generalOrderRecords?.filter((record) => record.orderDescription === GeneralOrderType.ISOLATION).map((record) => record.orderDetails);

    return {
      patientIdentifier: patient.clinicalSetup?.patientIdentifier || '',
      dob,
      sex,
      specifyBelow,
      gender,
      pronouns,
      height,
      weight,
      weightDateRecorded,
      mrn: patient.clinicalSetup?.mrn || '1234567',
      room: patient.clinicalSetup?.roomNumber || '555',
      provider: patient.clinicalSetup?.providerFirstLastInitial || '',
      clinicalUnit,
      allergies,
      nutrition,
      alerts,
      codeStatus,
      isolation,
      locale
    };
  };

  handleNavigate = (navId: string, path: string) => {
    this.props.setSelectedNavId(navId);
    navigationService.navigateToChart(path, this.props.assessment.eolsAssessmentId);
  };

  handleNavigateGeneralOrder = (navId: string, path: string, orderDescription: string) => {
    this.props.setSelectedNavId(navId);
    this.props.setOrderDescription(orderDescription);
    navigationService.navigateToChart(path, this.props.assessment.eolsAssessmentId);
  };

  render() {
    const { isLoading, isShowClinicalUnit } = this.state;

    const viewProps = {
      ...this.formattedPatientData(),
      isShowClinicalUnit,
      isLoading,
      onNavigate: this.handleNavigate,
      onNavigateGeneralOrder: this.handleNavigateGeneralOrder
    } as PatientRibbonViewProps;

    return <PatientRibbonView {...viewProps} />;
  }
}

const mapStateToProps = (state) => ({
  assessment: appSelectors.getAssessment(state),
  patient: appSelectors.getPatient(state),
  locale: appSelectors.getLocale(state) as Locales,
  userRole: appSelectors.getUserRole(state)
});

const mapDispatchToProps = (dispatch) => ({
  setPatient: (chartId) => dispatch(appActions.setPatient(chartId)),
  setOrderDescription: (orderDescription) => dispatch(studentActions.setSelectedOrderDescription(orderDescription)),
  setSelectedNavId: (navId) => dispatch(appActions.setSelectedNavId(navId))
});

export { PatientRibbon as BasePatientRibbon };
export default compose(injectIntl, connect(mapStateToProps, mapDispatchToProps))(PatientRibbon);
