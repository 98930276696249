import { LanguageKeys } from 'lang';
import { IntlShape } from 'react-intl';
import { Record } from 'models/api-response';
import { ChartMetaFormField, ChartTable } from 'models/ui';
import { DAY_IN_YEAR } from 'constants/app.constant';
import { buildFragmentRecords } from 'helpers/chart.helper';
import { convertFahrenheitToCelsius } from 'helpers/unit.converter';
import { buildPatientRecord, buildPatientRecords } from 'services/chart.service';
import {
  FormField,
  avpuAssessmentPointRanges,
  avpuAssessmentPointRangesLessOneYear,
  avpuAssessmentValueMap,
  bloodPressurePointRanges,
  bloodPressurePointRangesLessOneYear,
  pulsePointRanges,
  pulsePointRangesLessOneYear,
  respirationPointRanges,
  respirationPointRangesLessOneYear,
  saturationPointRanges,
  saturationPointRangesLessOneYear,
  temperaturePointRanges,
  temperaturePointRangesLessOneYear,
  vitalSignsDataPaths
} from './constants';
import {
  ADDSGetScores,
  ADDSMatch,
  ADDSPointMap,
  ADDSRange,
  ADDSRangeMap,
  ADDSScoringAction,
  ADDSScoringType,
  ADDSScoringValues,
  ADDSValueScoreMap,
  VitalSignsChartDataKey,
  VitalSignsDataSuperKey,
  VitalSignsFragmentRecords,
  VitalSignsSuperKeyValueMap
} from './types';

export interface GetADDSPointParams {
  rangeMap: ADDSRangeMap;
  value: string | number;
  compareType?: 'range' | 'match';
}

/**
 * Calculates the ADDS score based on the provided parameters.
 * @param rangeMap - The map of ranges and their corresponding scores.
 * @param value - The value to be evaluated.
 * @param compareType - The type of comparison to be performed ('range' or 'match').
 * @returns The ADDS score as a string.
 */
export const getADDSScore = ({ rangeMap, value, compareType = 'range' }: GetADDSPointParams): string => {
  if (value === null || value === undefined || value === '') return null;

  const transformedValue = compareType === 'range' ? Number(value) : String(value).toLowerCase();

  return Object.keys(rangeMap).find((point: string | number): string | number => {
    const ranges = rangeMap[point];

    return ranges.some(({ min, max, match }: ADDSRange & ADDSMatch) => {
      if (compareType === 'range' && typeof transformedValue === 'number') {
        return (!min || transformedValue >= min) && (!max || transformedValue <= max);
      }
      return match && transformedValue === match.toLowerCase();
    });
  });
};
const getRangeScoreByAge = (nameRange: ADDSScoringType, ageInDays: number) => {
  let scoreADDS = null;
  // eslint-disable-next-line sonarjs/no-small-switch
  switch (true) {
    case ageInDays < DAY_IN_YEAR:
      scoreADDS = {
        respiration: respirationPointRangesLessOneYear,
        saturation: saturationPointRangesLessOneYear,
        bloodPressure: bloodPressurePointRangesLessOneYear,
        avpuAssessment: avpuAssessmentPointRangesLessOneYear,
        pulse: pulsePointRangesLessOneYear,
        temperature: temperaturePointRangesLessOneYear
      };
      break;

    // TODO: Handle it later
    // case ageInDays >= DAY_IN_YEAR || ageInDays < 4 * DAY_IN_YEAR:
    //   scoreADDS = {
    //     respiration: respirationPointRanges,
    //     saturation: saturationPointRanges,
    //     bloodPressure: bloodPressurePointRanges,
    //     avpuAssessment: avpuAssessmentPointRanges,
    //     pulse: pulsePointRanges,
    //     temperature: temperaturePointRanges
    //   };
    //   break;

    default:
      scoreADDS = {
        respiration: respirationPointRanges,
        saturation: saturationPointRanges,
        bloodPressure: bloodPressurePointRanges,
        avpuAssessment: avpuAssessmentPointRanges,
        pulse: pulsePointRanges,
        temperature: temperaturePointRanges
      };
      break;
  }

  return scoreADDS[nameRange];
};
export const getADDSScores = (formFieldMap: Map<string, ChartMetaFormField>, ageInDays: number): ADDSGetScores => {
  const scoringValues: ADDSValueScoreMap = {
    respiration: formFieldMap.get(FormField.RESPIRATION)?.value,
    bloodPressure: formFieldMap.get(FormField.BLOOD_PRESSURE_SYSTOLIC)?.value,
    saturation: formFieldMap.get(FormField.OXYGEN_SATURATION)?.value,
    avpuAssessment: formFieldMap.get(FormField.DISABILITY_LEVEL_OF_RESPONSE_AVPU_ASSESSMENT)?.value,
    pulse: formFieldMap.get(FormField.PULSE)?.value,
    temperature: formFieldMap.get(FormField.TEMPERATURE_CELSIUS)?.value
  };
  const isShownScore = !!Object.values(scoringValues).filter((value) => value).length;

  const partialScores: ADDSGetScores['partialScores'] = {
    respiration: {
      value: scoringValues.respiration,
      score: getADDSScore({ rangeMap: getRangeScoreByAge(ADDSScoringValues.RESPIRATION, ageInDays), value: scoringValues.respiration })
    },
    bloodPressure: {
      value: scoringValues.bloodPressure,
      score: getADDSScore({ rangeMap: getRangeScoreByAge(ADDSScoringValues.BLOOD_PRESSURE, ageInDays), value: scoringValues.bloodPressure })
    },
    saturation: {
      value: scoringValues.saturation,
      score: getADDSScore({ rangeMap: getRangeScoreByAge(ADDSScoringValues.SATURATION, ageInDays), value: scoringValues.saturation })
    },
    avpuAssessment: {
      value: scoringValues.avpuAssessment,
      score: getADDSScore({ rangeMap: getRangeScoreByAge(ADDSScoringValues.AVPU_ASSESSMENT, ageInDays), value: scoringValues.avpuAssessment, compareType: 'match' })
    },
    pulse: {
      value: scoringValues.pulse,
      score: getADDSScore({ rangeMap: getRangeScoreByAge(ADDSScoringValues.PULSE, ageInDays), value: scoringValues.pulse })
    },
    temperature: {
      value: scoringValues.temperature,
      score: getADDSScore({ rangeMap: getRangeScoreByAge(ADDSScoringValues.TEMPERATURE, ageInDays), value: scoringValues.temperature })
    }
  };

  const emergencyCallScores = Object.values(partialScores).filter(({ score }) => score === ADDSPointMap.EMERGENCY_CALL);
  let totalScore = ADDSPointMap.EMERGENCY_CALL.toString();

  if (emergencyCallScores.length) {
    return {
      totalScore,
      partialScores
    };
  }

  totalScore = Object.values(partialScores).reduce((acc: string, { value, score }) => {
    if (!value || !score) {
      return acc;
    }
    return String(Number(acc) + Number(score));
  }, '0');

  return {
    totalScore: isShownScore ? totalScore : '',
    partialScores
  };
};

export const isScoreInRange = (scoreRanges: ADDSScoringAction['scoreRanges'], totalScore: string | number) =>
  scoreRanges.some(({ min, max, match }): boolean => {
    if (Number.isNaN(Number(totalScore)) && match) {
      return totalScore === match;
    }
    const transformedValue = Number(totalScore);
    return (!min || transformedValue >= min) && (!max || transformedValue <= max);
  });

export const getScoreByDataKey = (value: number, key: VitalSignsDataSuperKey | VitalSignsChartDataKey): string => {
  switch (key) {
    case VitalSignsDataSuperKey.BLOOD_PRESSURE:
    case VitalSignsChartDataKey.SYSTOLIC:
      return getADDSScore({ rangeMap: bloodPressurePointRanges, value });
    case VitalSignsDataSuperKey.SATURATION:
    case VitalSignsChartDataKey.SATURATION:
      return getADDSScore({ rangeMap: saturationPointRanges, value });
    case VitalSignsDataSuperKey.PULSE:
    case VitalSignsChartDataKey.PULSE:
      return getADDSScore({ rangeMap: pulsePointRanges, value });
    case VitalSignsDataSuperKey.RESPIRATION:
    case VitalSignsChartDataKey.RESPIRATION:
      return getADDSScore({ rangeMap: respirationPointRanges, value });
    case VitalSignsDataSuperKey.TEMPERATURE:
    case VitalSignsChartDataKey.TEMPERATURE:
      return getADDSScore({ rangeMap: temperaturePointRanges, value });
    case VitalSignsDataSuperKey.AVPU:
      return getADDSScore({ rangeMap: avpuAssessmentPointRanges, value, compareType: 'match' });
    default:
      return '0';
  }
};

export const accumulateScore = (totalScore: string, score: string): string => {
  if (!score) return totalScore;
  if (Number.isNaN(Number(totalScore)) || Number.isNaN(Number(score))) {
    return ADDSPointMap.EMERGENCY_CALL.toString();
  }
  return String(Number(totalScore) + Number(score));
};

export const getValueByPath = (path: string, obj) => path?.split('.').reduce((o, key) => o?.[key], obj);

// FIXME: fix complexity. Too much for this ticket so the refactor should be in a separated one.
// eslint-disable-next-line sonarjs/cognitive-complexity
export function extractVitalSignsSavedDataByHeaders(headers: VitalSignsDataSuperKey[], dataObject: object): VitalSignsSuperKeyValueMap {
  const result: VitalSignsSuperKeyValueMap = {} as VitalSignsSuperKeyValueMap;
  let totalScore = '0';

  headers.forEach((header) => {
    switch (header) {
      case VitalSignsDataSuperKey.BLOOD_PRESSURE: {
        const systolic = getValueByPath(vitalSignsDataPaths.BLOOD_PRESSURE?.['SYSTOLIC'], dataObject);
        const diastolic = getValueByPath(vitalSignsDataPaths.BLOOD_PRESSURE?.['DIASTOLIC'], dataObject);
        const score = getScoreByDataKey(systolic, VitalSignsDataSuperKey.BLOOD_PRESSURE);
        totalScore = accumulateScore(totalScore, score);
        result[header] = {
          value: systolic && diastolic ? `${systolic}/${diastolic}` : undefined,
          score
        };
        break;
      }
      case VitalSignsDataSuperKey.PAIN: {
        const rest = getValueByPath(vitalSignsDataPaths.PAIN?.['REST'], dataObject);
        const onMovement = getValueByPath(vitalSignsDataPaths.PAIN?.['ON_MOVEMENT'], dataObject);
        const painSegments: string[] = [rest ? `R(${rest})` : '', onMovement ? `M(${onMovement})` : ''].filter(Boolean);
        result[header] = {
          value: painSegments.length ? painSegments.join(' ') : undefined
        };
        break;
      }
      case VitalSignsDataSuperKey.AVPU: {
        const avpu = getValueByPath(vitalSignsDataPaths.PAIN?.['AVPU'], dataObject);
        const score = getScoreByDataKey(avpu, VitalSignsDataSuperKey.AVPU);
        totalScore = accumulateScore(totalScore, score);

        result[header] = {
          value: avpuAssessmentValueMap[avpu],
          score
        };
        break;
      }
      case VitalSignsDataSuperKey.TEMPERATURE: {
        const temperature = getValueByPath(String(vitalSignsDataPaths.TEMPERATURE), dataObject);
        const celsiusValue = convertFahrenheitToCelsius(temperature) as number;
        const score = getScoreByDataKey(celsiusValue, VitalSignsDataSuperKey.TEMPERATURE);
        totalScore = accumulateScore(totalScore, score);
        result[header] = {
          value: !Number.isNaN(Number(celsiusValue)) ? `${celsiusValue} C` : undefined,
          score
        };
        break;
      }
      default: {
        const path = vitalSignsDataPaths[header];
        const value = getValueByPath(path, dataObject);
        const score = getScoreByDataKey(value, header);
        totalScore = accumulateScore(totalScore, score);
        result[header] = {
          value,
          score
        };
        break;
      }
    }
  });

  const totalScoreMap = {
    [VitalSignsDataSuperKey.ADDS_SCORE]: {
      value: totalScore,
      customClassnames:
        totalScore === ADDSPointMap.EMERGENCY_CALL.toString()
          ? 'c-els-vitalSigns-data-table__adds-score--text-secondary c-els-vitalSigns-data-table__adds-score--bg-emergency-call'
          : 'c-els-vitalSigns-data-table__adds-score--text-secondary'
    }
  };

  return { ...result, ...totalScoreMap };
}

export function getStyledVitalSignsSavedData(data: ChartTable['data']) {
  const keys = Object.values(VitalSignsDataSuperKey);

  const transformedKeys = keys
    .filter((key) => ![VitalSignsDataSuperKey.CHARTING_AT].includes(key))
    .map((key) => ({
      value: key,
      customClassnames: key === VitalSignsDataSuperKey.ADDS_SCORE ? 'c-els-vitalSigns-data-table__adds-score' : ''
    }));

  const transformedData: VitalSignsSuperKeyValueMap[] = data
    .map((item) => {
      return {
        ...extractVitalSignsSavedDataByHeaders(keys, item),
        [VitalSignsDataSuperKey.CHARTING_AT]: { value: item?.['chartingAt'] },
        [VitalSignsDataSuperKey.CREATOR]: { value: item?.['creator']?.['id'] },
        [VitalSignsDataSuperKey.ACTIVE]: { value: item?.['active'] as boolean }
      } as VitalSignsSuperKeyValueMap;
    })
    .filter((item: VitalSignsSuperKeyValueMap) => item.CHARTING_AT.value)
    .sort((a: VitalSignsSuperKeyValueMap, b: VitalSignsSuperKeyValueMap) => new Date(String(a.CHARTING_AT.value)).getTime() - new Date(String(b.CHARTING_AT.value)).getTime());

  return {
    keys: transformedKeys,
    data: transformedData
  };
}

export const removeEmptyRecords = (rawRecord: Record): Record => {
  const cleanedChildrenRecords = rawRecord?.records.map((record) => {
    if (record?.records?.length) {
      const childRecords = record?.records;
      const cleanedChildRecords = childRecords?.filter((childRecord) => !!(childRecord?.value || childRecord?.content));
      return { ...record, records: cleanedChildRecords };
    }
    return record;
  });
  const cleanedRecords = cleanedChildrenRecords.filter((childRecord) => childRecord?.records?.length > 0);
  return { ...rawRecord, records: cleanedRecords };
};

export const getVitalSignsRecords = ({ formFieldMap, intl }: { formFieldMap: Map<string, ChartMetaFormField>; intl: IntlShape }): VitalSignsFragmentRecords => ({
  chartTitle: intl.formatMessage({ id: LanguageKeys.VITAL_SIGNS.VITAL_SIGNS }),
  fragmentTitle: intl.formatMessage({ id: LanguageKeys.VITAL_SIGNS.VITAL_SIGNS }),
  records: [
    {
      sectionTitle: LanguageKeys.VITAL_SIGNS.VITAL_SIGNS_AIRWAY,
      records: [
        buildPatientRecord(formFieldMap, FormField.AIRWAY_PATENT_OBSTRUCTED),
        buildPatientRecord(formFieldMap, FormField.AIRWAY_PARTIAL_OBSTRUCTION_COMPLETE_OBSTRUCTION),
        buildPatientRecord(formFieldMap, FormField.AIRWAY_OBSTRUCTED_CHILD_TICKBOX),
        buildPatientRecord(formFieldMap, FormField.AIRWAY_PARTIAL_OBSTRUCTION_CHILD_TICKBOX)
      ]
    },
    {
      sectionTitle: LanguageKeys.VITAL_SIGNS.RESPIRATION,
      records: [
        buildPatientRecord(formFieldMap, FormField.RESPIRATION),
        buildPatientRecord(formFieldMap, FormField.RESPIRATION_PATTERN),
        buildPatientRecord(formFieldMap, FormField.RESPIRATION_DEPTH)
      ]
    },
    {
      sectionTitle: LanguageKeys.VITAL_SIGNS.OXYGENATION,
      records: [
        buildPatientRecord(formFieldMap, FormField.OXYGEN_SATURATION),
        buildPatientRecord(formFieldMap, FormField.OXYGEN_SITE),
        buildPatientRecord(formFieldMap, FormField.OXYGEN_SITE_OTHER),
        buildPatientRecord(formFieldMap, FormField.OXYGEN_DELIVERY),
        buildPatientRecord(formFieldMap, FormField.OXYGEN_AMOUNT_LITER),
        buildPatientRecord(formFieldMap, FormField.OXYGEN_DELIVERY_METHOD_LITER),
        buildPatientRecord(formFieldMap, FormField.OXYGEN_DELIVERY_METHOD_LITER_OTHER),
        buildPatientRecord(formFieldMap, FormField.OXYGEN_AMOUNT_PERCENT),
        buildPatientRecord(formFieldMap, FormField.OXYGEN_DELIVERY_METHOD_PERCENT),
        buildPatientRecord(formFieldMap, FormField.OXYGEN_DELIVERY_METHOD_PERCENT_OTHER)
      ]
    },
    {
      sectionTitle: LanguageKeys.VITAL_SIGNS.AUSCULTATION,
      records: [
        buildPatientRecord(formFieldMap, FormField.AUSCULTATION_LEFT_LUNG),
        buildPatientRecord(formFieldMap, FormField.AUSCULTATION_LEFT_LUNG_ADVENTITIOUS_LUNG_SOUNDS_CRACKLES_WHEEZES),
        buildPatientRecord(formFieldMap, FormField.AUSCULTATION_RIGHT_LUNG),
        buildPatientRecord(formFieldMap, FormField.AUSCULTATION_RIGHT_LUNG_ADVENTITIOUS_LUNG_SOUNDS_CRACKLES_WHEEZES)
      ]
    },
    {
      sectionTitle: LanguageKeys.VITAL_SIGNS.PULSE,
      records: [
        buildPatientRecord(formFieldMap, FormField.PULSE),
        buildPatientRecord(formFieldMap, FormField.PULSE_SITE),
        buildPatientRecord(formFieldMap, FormField.PULSE_STRENGTH),
        buildPatientRecord(formFieldMap, FormField.PULSE_RHYTHM),
        buildPatientRecord(formFieldMap, FormField.PULSE_EQUALITY)
      ]
    },
    {
      sectionTitle: LanguageKeys.VITAL_SIGNS.BLOOD_PRESSURE,
      records: [
        buildPatientRecord(formFieldMap, FormField.BLOOD_PRESSURE_SYSTOLIC),
        buildPatientRecord(formFieldMap, FormField.BLOOD_PRESSURE_DIASTOLIC),
        buildPatientRecord(formFieldMap, FormField.BLOOD_PRESSURE_POSITION),
        buildPatientRecord(formFieldMap, FormField.BLOOD_PRESSURE_SITE),
        buildPatientRecord(formFieldMap, FormField.BLOOD_PRESSURE_MODE)
      ]
    },
    {
      sectionTitle: LanguageKeys.VITAL_SIGNS.PAIN_SCORE,
      records: [buildPatientRecord(formFieldMap, FormField.DISABILITY_PAIN_SCORE_REST), buildPatientRecord(formFieldMap, FormField.DISABILITY_PAIN_SCORE_ON_MOVEMENT)]
    },
    {
      sectionTitle: LanguageKeys.VITAL_SIGNS.LEVEL_OF_RESPONSE,
      records: [buildPatientRecord(formFieldMap, FormField.DISABILITY_LEVEL_OF_RESPONSE_AVPU_ASSESSMENT)]
    },
    {
      sectionTitle: LanguageKeys.VITAL_SIGNS.EXPOSURE,
      records: [buildPatientRecord(formFieldMap, FormField.EXPOSURE_COMMENTS)]
    },
    {
      sectionTitle: LanguageKeys.VITAL_SIGNS.TEMPERATURE,
      records: [
        buildPatientRecord(formFieldMap, FormField.TEMPERATURE_FAHRENHEIT),
        buildPatientRecord(formFieldMap, FormField.TEMPERATURE_CELSIUS),
        buildPatientRecord(formFieldMap, FormField.TEMPERATURE_SITE)
      ]
    },
    {
      parentSectionTitle: LanguageKeys.VITAL_SIGNS.ADDS_VERBOSE,
      sectionTitle: LanguageKeys.VITAL_SIGNS.SCORE,
      records: [
        buildPatientRecord(formFieldMap, FormField.ADDS_TOTAL_SCORE),
        ...buildPatientRecords(formFieldMap, FormField.NURSING_ACTIONS),
        buildPatientRecord(formFieldMap, FormField.NURSING_ACTIONS_COMMENT)
      ]
    },
    {
      sectionTitle: LanguageKeys.VITAL_SIGNS.NOTES,
      records: [buildPatientRecord(formFieldMap, FormField.NOTE)]
    }
  ]
});

export const buildVitalSignsFragmentRecords = ({ chartingTime, formFieldMap, intl }: { chartingTime: string; formFieldMap: Map<string, ChartMetaFormField>; intl: IntlShape }) =>
  buildFragmentRecords({
    chartingTime,
    formFieldMap,
    getRecords: () => getVitalSignsRecords({ formFieldMap, intl }),
    customRecordsCleaner: removeEmptyRecords
  });
