import cx from 'classnames';
import { Component } from 'react';
import { ChartFragment } from 'models/api-response';
import { ChartMetaFormField } from 'models/ui';
import { AppMessage } from 'constants/app.constant';
import { Box, ChartHistory } from 'components/common';
import { ELSFlex, ELSFlexItem } from 'components/common/els';
import { FormField, SectionTitle } from './constants';

export interface PCAFlowsheetDocumentProps {
  chartHistory: ChartFragment[];
  chartMetaFormFields: Map<string, ChartMetaFormField>;
  deleteHistory: Function;
}

class PCAFlowsheetDocument extends Component<PCAFlowsheetDocumentProps> {
  checkIfOtherField = (fieldValue: string): boolean => fieldValue === 'Other';

  orderMaintenanceIVRateRenderer = ({ orderMaintenanceIVRate, active }) => {
    return <div className={cx({ 'row--deleted': !active })}>{orderMaintenanceIVRate?.content || ''}</div>;
  };

  orderMaintenanceIVmLRenderer = ({ orderMaintenanceIVmL, active }) => {
    return <div className={cx({ 'row--deleted': !active })}>{orderMaintenanceIVmL?.content || ''}</div>;
  };

  orderAnalgesiaRenderer = ({ orderAnalgesia, orderAnalgesiaOther, active }) => {
    if (this.checkIfOtherField(orderAnalgesia)) {
      return this.orderAnalgesiaOtherRenderer({ orderAnalgesiaOther, active });
    }
    return <div className={cx({ 'row--deleted': !active })}>{orderAnalgesia?.content || ''}</div>;
  };

  orderAnalgesiaOtherRenderer = ({ orderAnalgesiaOther, active }) => {
    return <div className={cx({ 'row--deleted': !active })}>{orderAnalgesiaOther?.content || ''}</div>;
  };

  orderStartingDoseVolumeRenderer = ({ orderStartingDoseVolume, orderStartingDoseVolumeOther, active }) => {
    if (this.checkIfOtherField(orderStartingDoseVolume)) {
      return this.orderStartingDoseVolumeOtherRenderer({ orderStartingDoseVolumeOther, active });
    }
    return <div className={cx({ 'row--deleted': !active })}>{orderStartingDoseVolume?.content || ''}</div>;
  };

  orderStartingDoseVolumeOtherRenderer = ({ orderStartingDoseVolumeOther, active }) => {
    return <div className={cx({ 'row--deleted': !active })}>{orderStartingDoseVolumeOther?.content || ''}</div>;
  };

  orderDelayIntervalRenderer = ({ orderDelayInterval, orderDelayIntervalOther, active }) => {
    if (this.checkIfOtherField(orderDelayInterval)) {
      return this.orderDelayIntervalOtherRenderer({ orderDelayIntervalOther, active });
    }
    return <div className={cx({ 'row--deleted': !active })}>{orderDelayInterval?.content || ''}</div>;
  };

  orderDelayIntervalOtherRenderer = ({ orderDelayIntervalOther, active }) => {
    return <div className={cx({ 'row--deleted': !active })}>{orderDelayIntervalOther?.content || ''}</div>;
  };

  orderBasalRateRenderer = ({ orderBasalRate, active }) => {
    return <div className={cx({ 'row--deleted': !active })}>{orderBasalRate?.content || ''}</div>;
  };

  orderStarting1HourLimitRenderer = ({ orderStarting1HourLimit, orderStarting1HourLimitOther, active }) => {
    if (this.checkIfOtherField(orderStarting1HourLimit)) {
      return this.orderStarting1HourLimitOtherRenderer({ orderStarting1HourLimitOther, active });
    }
    return <div className={cx({ 'row--deleted': !active })}>{orderStarting1HourLimit?.content || ''}</div>;
  };

  orderStarting1HourLimitOtherRenderer = ({ orderStarting1HourLimitOther, active }) => {
    return <div className={cx({ 'row--deleted': !active })}>{orderStarting1HourLimitOther?.content || ''}</div>;
  };

  orderBolusLoadingRenderer = ({ orderBolusLoading, orderBolusLoadingOther, active }) => {
    if (this.checkIfOtherField(orderBolusLoading)) {
      return this.orderBolusLoadingOtherRenderer({ orderBolusLoadingOther, active });
    }
    return <div className={cx({ 'row--deleted': !active })}>{orderBolusLoading?.content || ''}</div>;
  };

  orderBolusLoadingOtherRenderer = ({ orderBolusLoadingOther, active }) => {
    return <div className={cx({ 'row--deleted': !active })}>{orderBolusLoadingOther?.content || ''}</div>;
  };

  orderUnrelievedPainRenderer = ({ orderUnrelievedPain, active }) => {
    return <div className={cx({ 'row--deleted': !active })}>{orderUnrelievedPain?.content || ''}</div>;
  };

  monitoringPainAssessmentScoreRenderer = ({ monitoringPainAssessmentScore, active }) => {
    return <div className={cx({ 'row--deleted': !active })}>{monitoringPainAssessmentScore?.content || ''}</div>;
  };

  monitoringRespiratoryRateRenderer = ({ monitoringRespiratoryRate, active }) => {
    return <div className={cx({ 'row--deleted': !active })}>{monitoringRespiratoryRate?.content || ''}</div>;
  };

  monitoringOxygenSaturationRenderer = ({ monitoringOxygenSaturation, monitoringOxygenSaturationO2, active }) => {
    return (
      <div className={cx({ 'row--deleted': !active })}>
        <div>{monitoringOxygenSaturation?.content || ''}</div>
        {this.monitoringOxygenSaturationO2Renderer({ monitoringOxygenSaturationO2 })}
      </div>
    );
  };

  monitoringOxygenSaturationO2Renderer = ({ monitoringOxygenSaturationO2 }) => {
    return <div>{monitoringOxygenSaturationO2?.content || ''}</div>;
  };

  monitoringExcessiveSedationRenderer = ({ monitoringExcessiveSedation, active }) => {
    return <div className={cx({ 'row--deleted': !active })}>{monitoringExcessiveSedation?.content || ''}</div>;
  };

  monitoringPersistentPainRenderer = ({ monitoringPersistentPain, active }) => {
    return <div className={cx({ 'row--deleted': !active })}>{monitoringPersistentPain?.content || ''}</div>;
  };

  monitoringNauseaRenderer = ({ monitoringNausea, active }) => {
    return <div className={cx({ 'row--deleted': !active })}>{monitoringNausea?.content || ''}</div>;
  };

  monitoringVomitingRenderer = ({ monitoringVomiting, active }) => {
    return <div className={cx({ 'row--deleted': !active })}>{monitoringVomiting?.content || ''}</div>;
  };

  monitoringItchingRenderer = ({ monitoringItching, active }) => {
    return <div className={cx({ 'row--deleted': !active })}>{monitoringItching?.content || ''}</div>;
  };

  monitoringProviderNotifiedOfPainRenderer = ({ monitoringProviderNotifiedOfPain, active }) => {
    return <div className={cx({ 'row--deleted': !active })}>{monitoringProviderNotifiedOfPain?.content || ''}</div>;
  };

  monitoringBriefNotesRenderer = ({ monitoringBriefNotes, active }) => {
    return <div className={cx({ 'row--deleted': !active })}>{monitoringBriefNotes?.content || ''}</div>;
  };

  render() {
    const { chartHistory, deleteHistory } = this.props;
    const dataSource = {
      headers: [
        { text: 'Time', field: 'chartTimeActionDelete', section: 'Time' },
        { text: SectionTitle.ORDER_MAINTENANCE_IV_RATE, field: FormField.ORDER_MAINTENANCE_IV_RATE, section: 'Order', customRender: this.orderMaintenanceIVRateRenderer },
        { text: SectionTitle.ORDER_MAINTENANCE_IV_ML, field: FormField.ORDER_MAINTENANCE_IV_ML, section: 'Order', customRender: this.orderMaintenanceIVmLRenderer },
        { text: SectionTitle.ORDER_ANALGESIA, field: FormField.ORDER_ANALGESIA, section: 'Order', customRender: this.orderAnalgesiaRenderer },
        { text: SectionTitle.ORDER_STARTING_DOSE_VOLUME, field: FormField.ORDER_STARTING_DOSE_VOLUME, section: 'Order', customRender: this.orderStartingDoseVolumeRenderer },
        { text: SectionTitle.ORDER_DELAY_INTERVAL, field: FormField.ORDER_DELAY_INTERVAL, section: 'Order', customRender: this.orderDelayIntervalRenderer },
        { text: SectionTitle.ORDER_BASAL_RATE, field: FormField.ORDER_BASAL_RATE, section: 'Order', customRender: this.orderBasalRateRenderer },
        { text: SectionTitle.ORDER_STARTING1_HOUR_LIMIT, field: FormField.ORDER_STARTING1_HOUR_LIMIT, section: 'Order', customRender: this.orderStarting1HourLimitRenderer },
        { text: SectionTitle.ORDER_BOLUS_LOADING, field: FormField.ORDER_BOLUS_LOADING, section: 'Order', customRender: this.orderBolusLoadingRenderer },
        { text: SectionTitle.ORDER_UNRELIEVED_PAIN, field: FormField.ORDER_UNRELIEVED_PAIN, section: 'Order', customRender: this.orderUnrelievedPainRenderer },
        {
          text: SectionTitle.MONITORING_PAIN_ASSESSMENT_SCORE,
          field: FormField.MONITORING_PAIN_ASSESSMENT_SCORE,
          section: 'Monitoring',
          customRender: this.monitoringPainAssessmentScoreRenderer
        },
        {
          text: SectionTitle.MONITORING_RESPIRATORY_RATE,
          field: FormField.MONITORING_RESPIRATORY_RATE,
          section: 'Monitoring',
          customRender: this.monitoringRespiratoryRateRenderer
        },
        {
          text: SectionTitle.MONITORING_OXYGEN_SATURATION,
          field: FormField.MONITORING_OXYGEN_SATURATION,
          section: 'Monitoring',
          customRender: this.monitoringOxygenSaturationRenderer
        },
        {
          text: SectionTitle.MONITORING_EXCESSIVE_SEDATION,
          field: FormField.MONITORING_EXCESSIVE_SEDATION,
          section: 'Monitoring',
          customRender: this.monitoringExcessiveSedationRenderer
        },
        { text: SectionTitle.MONITORING_PERSISTENT_PAIN, field: FormField.MONITORING_PERSISTENT_PAIN, section: 'Monitoring', customRender: this.monitoringPersistentPainRenderer },
        { text: SectionTitle.MONITORING_NAUSEA, field: FormField.MONITORING_NAUSEA, section: 'Monitoring', customRender: this.monitoringNauseaRenderer },
        { text: SectionTitle.MONITORING_VOMITING, field: FormField.MONITORING_VOMITING, section: 'Monitoring', customRender: this.monitoringVomitingRenderer },
        { text: SectionTitle.MONITORING_ITCHING, field: FormField.MONITORING_ITCHING, section: 'Monitoring', customRender: this.monitoringItchingRenderer },
        {
          text: SectionTitle.MONITORING_PROVIDER_NOTIFIED_OF_PAIN,
          field: FormField.MONITORING_PROVIDER_NOTIFIED_OF_PAIN,
          section: 'Monitoring',
          customRender: this.monitoringProviderNotifiedOfPainRenderer
        },
        { text: SectionTitle.MONITORING_BRIEF_NOTES, field: FormField.MONITORING_BRIEF_NOTES, section: 'Monitoring', customRender: this.monitoringBriefNotesRenderer }
      ],
      data: chartHistory
    };

    return (
      <Box mt className="pca-flowsheet-document">
        <ELSFlex left wrap>
          <ELSFlexItem w={chartHistory.length === 0 ? '1o3' : '1o1'}>
            <ChartHistory noTableDataMessage=" " renderVertical tableTitleContents={['Time', 'Order', 'Monitoring']} dataSource={dataSource} deleteRecord={deleteHistory} />
          </ELSFlexItem>
          {chartHistory.length === 0 && (
            <ELSFlexItem w="2o3">
              <div className="margin-top-4x1o2 u-els-margin-left">{AppMessage.NO_TABLE_DATA}</div>
            </ELSFlexItem>
          )}
        </ELSFlex>
      </Box>
    );
  }
}

export default PCAFlowsheetDocument;
