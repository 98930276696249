import { LanguageKeys } from 'lang';
import { useEffect, useState } from 'react';
import { ChartFragment } from 'models/api-response';
import { ChartActionsComponentProps } from 'models/ui';
import { chartHelper, cssHelper } from 'helpers';
import { Avatar, Box, ChartActions, ChartPermission, ErrorCard, ErrorFormField, TextBlock } from 'components/common';
import { ELSFlex, ELSFlexItem } from 'components/common/els';
import { FormField } from './constants';
import SectionHeader from './SectionHeader';
import './clinical.setup.scss';

export interface ClinicalSetupViewProps {
  fragment: ChartFragment;
  chartMetaFormFields;
  formSubmittedCount: number;
  patientIdentifierAvatar: string;
  studentFirstInitial: string;
  studentLastName: string;
  chartActionsProps: ChartActionsComponentProps;
  isShowClinicalUnit: boolean;
  isAuthor: boolean;
}

const MAX_LENGTH_INPUT = 10;
const ClinicalSetupView = ({
  fragment,
  formSubmittedCount,
  chartMetaFormFields,
  patientIdentifierAvatar,
  chartActionsProps,
  isShowClinicalUnit,
  isAuthor
}: ClinicalSetupViewProps) => {
  const [isSpecifyBelowShown, setIsSpecifyBelowShown] = useState(false);
  const patientSexValue = chartMetaFormFields.get(FormField.PATIENT_SEX)?.value;
  const sexOnChangeEvent = (_field, e) => {
    if (e.target?.value === 'anotherTerm') {
      setIsSpecifyBelowShown(true);
    } else {
      setIsSpecifyBelowShown(false);
    }
  };
  const handleMaxLengthChangeEvent = (e) => {
    if (e.target.value.length > MAX_LENGTH_INPUT) {
      // eslint-disable-next-line no-param-reassign
      e.target.value = e.target.value.slice(0, MAX_LENGTH_INPUT);
    }
  };
  useEffect(() => {
    const timeOut = setTimeout(() => {
      const select = document.querySelector('#field-input-patientSex');
      if (select && chartMetaFormFields.get(FormField.PATIENT_SEX)?.value === 'anotherTerm') {
        select.dispatchEvent(new Event('change', { bubbles: true }));
      }
    }, 500);
    return () => clearTimeout(timeOut);
  }, [patientSexValue]);
  return (
    <div className="clinical-setup">
      <div>
        <ELSFlex>
          <ELSFlexItem w="8o12">
            <div className={cssHelper.mapElsCss({ els: 'padding-right-3x', md: 'padding-right-1x1o2', lg: 'padding-right-2x' })}>
              <h2 className="clinical-setup__header">Clinical Setup</h2>
              <Box mt>Complete the following demographic information to create a patient for charting.</Box>
              <ErrorCard chartMetaFormFields={chartMetaFormFields} showErrorCard={formSubmittedCount > 0} />
              {(formSubmittedCount === 0 || !chartHelper.chartHasErrors(chartMetaFormFields)) && (
                <Box mt2>
                  <strong>All fields are required</strong>
                </Box>
              )}
              <SectionHeader title={chartMetaFormFields.get(FormField.CLINICAL_INFO)?.label} tooltip={chartMetaFormFields.get(FormField.CLINICAL_INFO)?.labelTooltip} />
              <ErrorFormField
                formField={chartMetaFormFields.get(FormField.CLINICAL_FIRST_DAY)}
                formSubmittedCount={formSubmittedCount}
                inputProps={{ inputAriaLabel: LanguageKeys.CLINICAL_SETUP.FIRST_DATE_INPUT }}
              />
              {isShowClinicalUnit && (
                <ErrorFormField
                  formField={chartMetaFormFields.get(FormField.CLINICAL_UNIT)}
                  formSubmittedCount={formSubmittedCount}
                  options={{
                    displayHorizontal: true
                  }}
                />
              )}
              <div>
                <SectionHeader>Patient info</SectionHeader>
                <ELSFlex className="clinical-setup__patient" left>
                  <ELSFlexItem className="clinical-setup__patient-avatar">
                    <div className="clinical-setup__avatar-wrapper">
                      <div className="clinical-setup__avatar-text">Avatar</div>
                      <Avatar name={patientIdentifierAvatar} className="u-els-margin-top-1o2 u-els-text-center" />
                    </div>
                  </ELSFlexItem>
                  <ELSFlexItem className="clinical-setup__patient-input">
                    <ErrorFormField formField={chartMetaFormFields.get(FormField.PATIENT_IDENTIFIER)} formSubmittedCount={formSubmittedCount} />
                    <ELSFlex wrap className="clinical-setup__patient-sex">
                      <ELSFlexItem className="clinical-setup__patient-sex-info">
                        <ErrorFormField
                          formField={chartMetaFormFields.get(FormField.PATIENT_SEX)}
                          formSubmittedCount={formSubmittedCount}
                          onChange={(e) => sexOnChangeEvent(chartMetaFormFields.get(FormField.PATIENT_SEX), e)}
                        />
                      </ELSFlexItem>
                      {isSpecifyBelowShown && (
                        <ELSFlexItem className="clinical-setup__patient-specify-below">
                          <ErrorFormField
                            formField={chartMetaFormFields.get(FormField.SPECIFY_BELOW)}
                            formSubmittedCount={formSubmittedCount}
                            onChange={handleMaxLengthChangeEvent}
                          />
                        </ELSFlexItem>
                      )}
                    </ELSFlex>
                    <ELSFlex wrap className="clinical-setup__patient-gender">
                      <ELSFlexItem className="clinical-setup__patient-gender-info">
                        <ErrorFormField formField={chartMetaFormFields.get(FormField.GENDER)} formSubmittedCount={formSubmittedCount} />
                      </ELSFlexItem>
                      <ELSFlexItem className="clinical-setup__patient-pronouns">
                        <ErrorFormField formField={chartMetaFormFields.get(FormField.PRONOUNS)} formSubmittedCount={formSubmittedCount} onChange={handleMaxLengthChangeEvent} />
                      </ELSFlexItem>
                    </ELSFlex>
                    <ErrorFormField
                      formField={chartMetaFormFields.get(FormField.PATIENT_DOB)}
                      formSubmittedCount={formSubmittedCount}
                      inputProps={{ inputAriaLabel: LanguageKeys.CLINICAL_SETUP.BIRTH_DATE_INPUT }}
                    />
                    <ELSFlex wrap className="clinical-setup__patient-mrn">
                      <ELSFlexItem className="clinical-setup__patient-mrn-info">
                        <ErrorFormField
                          data-testid="patient-mrn"
                          formField={chartMetaFormFields.get(FormField.MRN)}
                          formSubmittedCount={formSubmittedCount}
                          onChange={handleMaxLengthChangeEvent}
                        />
                      </ELSFlexItem>
                      <ELSFlexItem className="clinical-setup__patient-room">
                        <ErrorFormField
                          data-testid="patient-roomNumber"
                          formField={chartMetaFormFields.get(FormField.ROOM_NUMBER)}
                          formSubmittedCount={formSubmittedCount}
                          onChange={handleMaxLengthChangeEvent}
                        />
                      </ELSFlexItem>
                    </ELSFlex>
                  </ELSFlexItem>
                </ELSFlex>
              </div>
              <SectionHeader title={chartMetaFormFields.get(FormField.PROVIDER_INFO)?.label} tooltip={chartMetaFormFields.get(FormField.PROVIDER_INFO)?.labelTooltip} />
              <ErrorFormField formField={chartMetaFormFields.get(FormField.PROVIDER_FIRST_LAST_INITIAL)} formSubmittedCount={formSubmittedCount} />
              {isAuthor && (
                <Box mt>
                  <ErrorFormField formField={chartMetaFormFields.get(FormField.PATIENT_NAME)} formSubmittedCount={formSubmittedCount} />
                  <ErrorFormField formField={chartMetaFormFields.get(FormField.MRN)} formSubmittedCount={formSubmittedCount} />
                  <ErrorFormField formField={chartMetaFormFields.get(FormField.ROOM_NUMBER)} formSubmittedCount={formSubmittedCount} />
                </Box>
              )}
              <ChartPermission fragmentType={fragment?.fragmentType}>{({ allowSaveFragment }) => allowSaveFragment && <ChartActions {...chartActionsProps} />}</ChartPermission>
            </div>
          </ELSFlexItem>
          <ELSFlexItem w="4o12">
            <TextBlock
              className={cssHelper.mapElsCss({ els: 'padding-left-3x', md: 'padding-left-1x1o2', lg: 'padding-left-2x' })}
              heading={chartMetaFormFields.get(FormField.HIPAA)?.label}
              content={chartMetaFormFields.get(FormField.HIPAA)?.labelTooltip}
            />
          </ELSFlexItem>
        </ELSFlex>
      </div>
    </div>
  );
};

ClinicalSetupView.displayName = 'ClinicalSetupView';
export default ClinicalSetupView;
