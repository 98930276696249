import { AssessmentStatus, EventType, GradingResult, PhaseProgress } from 'models/enum';
import { CaseStudySequenceChunkSummary, TimeSpent, TimeSpentPartial } from 'models/ui';
import { AppConstant, assignmentIdType } from 'constants/app.constant';
import { assignmentService } from 'services';
import moment from 'moment/moment';
import { roundUp } from './app.helper';

export const getScore = (totalCorrect: number, totalQuestions: number, assessmentStatus: string) => {
  if (assessmentStatus !== AssessmentStatus.COMPLETED) {
    return 0;
  }

  if (totalCorrect === null || totalQuestions === null || totalQuestions === 0) {
    return null;
  }
  return roundUp((totalCorrect * 100) / totalQuestions);
};

export const getPrettyScore = (score: number) => {
  if (score === null) {
    return 'na';
  }
  return `${score}%`;
};

export const getPrettyStatus = (status: AssessmentStatus): string => {
  const allStatuses = {
    [AssessmentStatus.NOT_STARTED]: 'Not Started',
    [AssessmentStatus.IN_PROGRESS]: 'In Progress',
    [AssessmentStatus.COMPLETED]: 'Completed',
    [AssessmentStatus.COMPLETED_LATE]: 'Completed Late',
    [AssessmentStatus.PAST_DUE]: 'Past Due'
  };
  return allStatuses[status];
};

export const getPrettyGradingResult = (result: GradingResult): string => {
  const allResults = {
    [GradingResult.PASS]: 'Pass',
    [GradingResult.FAIL]: 'Fail',
    [GradingResult.NA]: 'na'
  };
  return allResults[result];
};

export const isAssessmentSubmitted = (assessmentStatus: string): boolean =>
  [AssessmentStatus.COMPLETED.toString(), AssessmentStatus.COMPLETED_LATE.toString()].includes(assessmentStatus);

export const isAssessmentNotStartedOrInProgress = (assessmentStatus: string): boolean =>
  [AssessmentStatus.NOT_STARTED.toString(), AssessmentStatus.IN_PROGRESS.toString()].includes(assessmentStatus);

export const isAssessmentGradingAllowed = (isInProgressGradingEnabledFlag: boolean, assessmentStatus: string) =>
  (isInProgressGradingEnabledFlag && [AssessmentStatus.IN_PROGRESS.toString(), AssessmentStatus.PAST_DUE.toString()].includes(assessmentStatus)) ||
  isAssessmentSubmitted(assessmentStatus);

export const checkForFakeId = (url: string) => {
  // Regex checks for a number of any length starting with -
  const regexForFake = new RegExp(/^-\d*\d$/);
  // Regex checks for a number of any length only
  const regexForReal = new RegExp(/^\d*\d$/);
  const splitUrl = url.split('/');
  const fakeId = regexForFake.test(splitUrl[3]) ? assignmentIdType.fakeAssignmentId : null;
  const realId = regexForReal.test(splitUrl[3]) ? assignmentIdType.realAssignmentId : null;

  return fakeId || realId || null;
};

export const getEmptyEhrDemo = () => {
  const globalSimsNGState = JSON.parse(localStorage.getItem(AppConstant.PERSIST_KEY));
  return globalSimsNGState?.instructorState?.emptyEhrDemo;
};

export const isDemoEmptyEhr = () => {
  const emptyEhrDemo = getEmptyEhrDemo();
  return emptyEhrDemo?.assessment?.demoAssessmentId < 0 && emptyEhrDemo?.assessment?.demoAssignmentId < 0;
};

export const resetEmptyEhrDemoStorage = () => {
  const globalSimsNGState = JSON.parse(localStorage.getItem(AppConstant.PERSIST_KEY));
  if (globalSimsNGState?.instructorState?.emptyEhrDemo) {
    globalSimsNGState.instructorState.emptyEhrDemo = null;
    localStorage.setItem(AppConstant.PERSIST_KEY, JSON.stringify(globalSimsNGState));
  }
};

export const recordTimeSpent = (data: TimeSpentPartial, eventType: EventType): void => {
  const recordTimeSpentData: TimeSpent = {
    assignmentId: data.assignmentId,
    assessmentId: data.assessmentId,
    userId: data.userId,
    eventTime: moment().toISOString(),
    phaseIndex: data.phaseIndex,
    currentLocation: {
      id: data.currentLocation.id,
      type: data.currentLocation.type
    },
    eventType
  };

  assignmentService.sendTimeSpent(recordTimeSpentData);
};

export const getIncorrectQuestions = (sequenceChunkSummaries: CaseStudySequenceChunkSummary[]): number => {
  let inCorrectCount = 0;
  const completedPhases = sequenceChunkSummaries?.filter(phase => (phase.status === PhaseProgress.COMPLETED || phase.status === PhaseProgress.IN_PROGRESS));
  completedPhases?.forEach(phase => {
    phase.stepChunks?.forEach(question => {
      if(!question.correct) {
        inCorrectCount = inCorrectCount + 1;
      }
    });
  });
  return inCorrectCount;
};

export const checkIfAnyPhaseCompleted = (sequenceChunkSummaries: CaseStudySequenceChunkSummary[]) =>  {
  const completedPhase = sequenceChunkSummaries?.find(phase => phase.status === PhaseProgress.COMPLETED);
  if(completedPhase) {
    return true;
  } else {
    return false;
  }
};
