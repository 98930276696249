import cx from 'classnames';
import { get, isNil } from 'lodash';
import { Component, ReactElement } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { compose } from 'recompose';
import { ELSFooter } from '@els/els-component-footer-react';
import { AssessmentRS } from 'models/api-response';
import { ELSModalServiceType } from 'models/els';
import { AssessmentStatus, AssignmentType, EventType, HOSPITAL_FLOOR, LocationType } from 'models/enum';
import { CaseStudySequence, NavigationItem, PatientContext, SimChartAssignment, SkinnyAssignment, SkipLinkSection, TimeSpentPartial, User } from 'models/ui';
import { AppConstant, AuthoringFakeData, ExcludesSkipLinkPaths, Locales, NAV_ID, RouteParams, SkipLinkId } from 'constants/app.constant';
import { apiHelper, appHelper, assignmentHelper, cssHelper } from 'helpers';
import { assignmentService, caseStudyService, featureService, navigationService } from 'services';
import { appActions, appSelectors } from 'redux/ducks/app';
import { studentActions, studentSelectors } from 'redux/ducks/student';
import { Box, GradingChart, PageHeader } from 'components/common';
import { ELSButton, ELSFlex, ELSFlexItem, ELSIcon, ELSWithModalService } from 'components/common/els';
import { AssignmentBar, Breadcrumb, Navigation, PatientRibbon } from 'components/common/layout';
import AssessmentSubmission from 'components/features/assessment-submission/AssessmentSubmission';
import './page.wrapper.scss';

interface MatchParams {
  assessmentId: string;
}

interface PageWrapperPropsOnly {
  children(arg: { loggedInUser: User; userRole: string; locale: string }): ReactElement;
  loggedInUser: User;
  userRole: string;
  showPatientRibbon: boolean;
  showNavigation: boolean;
  showChartingDateTime: boolean;
  menuItems: NavigationItem[];
  title: string;
  assignment: SkinnyAssignment;
  assessment: AssessmentRS;
  getMenuItems: (assessmentId: number, patientContext: PatientContext, skipCache?: boolean) => any;
  selectedNavId: string;
  isPlaceHolder: boolean;
  showSubmitAssessmentButton: boolean;
  modalService: ELSModalServiceType;
  isAuthor: boolean;
  phaseIndex: number;
  setSkipLinkSections: Function;
  setAssessment: Function;
  toggleFlyout: (content: JSX.Element | null) => void;
  setAssignment: Function;
  setAssignmentType: Function;
  setChartingTime: Function;
  fetchPatientContext: Function;
  setPatientContext: Function;
  setPhaseIndex: Function;
  setSelectedNavId: Function;
  setTimeSpentData: Function;
  locale: Locales;
  assignmentType: string;
  fullAssignment: SimChartAssignment;
}

interface PageWrapperState {
  openNavigation: boolean;
  isDataLoaded: boolean;
  filteredShownMenuItems: NavigationItem[];
  isInProgressGradingEnabled: boolean;
  isDemoMode: boolean;
}

export type PageWrapperProps = PageWrapperPropsOnly & RouteComponentProps<MatchParams>;

class PageWrapper extends Component<PageWrapperProps, PageWrapperState> {
  static displayName = 'PageWrapper';
  static defaultProps = {
    title: 'SimChart Assignment',
    showPatientRibbon: true,
    showNavigation: true,
    showSubmitAssessmentButton: true
  };

  constructor(props) {
    super(props);
    const shouldCollapseNavigation = appHelper.getWindowMode().mobile || appHelper.getWindowMode().tablet;
    this.state = {
      openNavigation: !shouldCollapseNavigation,
      isDataLoaded: false,
      filteredShownMenuItems: [],
      isInProgressGradingEnabled: false,
      isDemoMode: appHelper.isDemoMode()
    };
  }

  async componentDidMount() {
    const { location, setSelectedNavId } = this.props;
    this.sendCurrentPhaseIndex(Number(this.props.match.params.assessmentId));
    this.loadData();
    if (location.pathname.endsWith('summary')) {
      setSelectedNavId(NAV_ID.SUMMARY);
    }

    const isInProgressGradingEnabled = await featureService.isInProgressGradingEnabled();
    this.setState({ isInProgressGradingEnabled });
  }

  componentDidUpdate(prevProps: Readonly<PageWrapperProps>) {
    const { assignmentType, menuItems, locale } = this.props;
    if (menuItems !== prevProps.menuItems && menuItems) {
      this.setState({ filteredShownMenuItems: navigationService.filterShownMenuItems(menuItems) });
    }

    // Reload data when locale changes.
    // This reload will fetch new patient context which contains new locale.
    // It should be done before fetchChartMetadata in withChartMetadata HOC.
    if (prevProps.locale !== locale) {
      this.loadData();
    }

    if (assignmentType !== prevProps.assignmentType) {
      this.sendCurrentPhaseIndex(Number(this.props.match.params.assessmentId));
    }
  }

  // Only sets partial data needed for sendTimeSpent api call
  setChartTimeSpentData = (): TimeSpentPartial => {
    const { assignment, phaseIndex, selectedNavId, setTimeSpentData } = this.props;
    // Set to 0 when assignmentId shows undef/null on pages like Instructor demo
    const assignmentId = isNil(assignment?.assignmentId) ? 0 : assignment.assignmentId;
    const timeSpentPartialData: TimeSpentPartial = {
      assignmentId: isNil(assignmentId) ? 0 : assignmentId,
      assessmentId: this.props.assessment.eolsAssessmentId,
      userId: this.props.loggedInUser.id,
      phaseIndex,
      currentLocation: {
        id: selectedNavId,
        type: LocationType.PHASE_CHART
      }
    };
    setTimeSpentData(timeSpentPartialData);
    // Quick return for START calls made in PageWrapper
    return timeSpentPartialData;
  };

  loadData = () => {
    const { location, isAuthor, getMenuItems, setAssessment, setAssignmentType, setPatientContext, setPhaseIndex, setSkipLinkSections, locale } = this.props;
    const { assessmentId } = this.props.match.params;
    let promise;

    /**
     * if user is an author
     * just get menu items
     * if the user isn't an author and the assessmentId is defined
     * get assessment and menu items
     */

    if (isAuthor) {
      const hospitalFloors = [HOSPITAL_FLOOR.MEDSURG, HOSPITAL_FLOOR.PEDIATRIC, HOSPITAL_FLOOR.PSYCH, HOSPITAL_FLOOR.WOMAN_HEALTH];
      const authorPatientContext: PatientContext = {
        assignmentType: AssignmentType.CASE_STUDY,
        gender: 'MALE',
        ageInDays: null,
        hospitalFloors,
        isAuthor,
        locale
      };
      // Skip cache for authoring.
      promise = getMenuItems(Number(AuthoringFakeData.assessmentId), authorPatientContext, true);
      setAssessment(AuthoringFakeData.assessment);
      setAssignmentType(AssignmentType.CASE_STUDY);
      setPatientContext(authorPatientContext);
    } else if (assessmentId) {
      const sections: SkipLinkSection[] = [];
      const searchPath = location.pathname.replace(String(assessmentId), RouteParams.ASSESSMENT_ID);
      if (!ExcludesSkipLinkPaths.includes(searchPath)) {
        sections.push(SkipLinkId.navigation, SkipLinkId.main, SkipLinkId.footer);
      }
      setSkipLinkSections(sections);

      promise = this.fetchAssessment(Number(assessmentId)).then(async (data) => {
        const isEmptyEhr = data.assignmentType === AssignmentType.EMPTY_EHR;
        if (isEmptyEhr && !isAuthor) {
          setPhaseIndex(0);
          assignmentHelper.recordTimeSpent(this.setChartTimeSpentData(), EventType.START);
        }

        const patientContext = await this.props.fetchPatientContext(data.simChartId, data.assignmentType);
        if (patientContext) {
          return getMenuItems(Number(assessmentId), patientContext);
        }
        return [];
      });
    }
    return Promise.resolve(promise).then(() => {
      this.setState({ isDataLoaded: true });
    });
  };

  sendCurrentPhaseIndex = (assessmentId: number) => {
    const { assignmentType, isAuthor, setPhaseIndex } = this.props;
    if (assignmentType === AssignmentType.CASE_STUDY) {
      caseStudyService.getCurrentOrFirstSequenceChunk(assessmentId).then(({ data }: { data: CaseStudySequence }) => {
        localStorage.setItem(AppConstant.PHASE_ID, data?.phaseId);
        const index = data.displayOrder;
        const currentPhaseIndex = index > 0 ? index : 0;
        setPhaseIndex(currentPhaseIndex);

        if (!isAuthor) {
          assignmentHelper.recordTimeSpent(this.setChartTimeSpentData(), EventType.START);
        }
      });
    }
  };

  fetchAssessment = (assessmentId: number) => {
    const { assignment, setAssessment, setAssignment, setAssignmentType } = this.props;

    return apiHelper.withApiErrorHandler(async () => {
      const assessmentResponse = await assignmentService.getAssessment(assessmentId);
      const assessmentData = get(assessmentResponse, 'data', assessmentResponse);

      if (assignment?.assignmentId !== assessmentData.eolsAssignmentId) {
        const assignmentResponse = await assignmentService.getSimChartAssignment(assessmentData.eolsAssignmentId);
        setAssignment({ ...assignmentResponse.data, assignmentId: assignmentResponse.data.eolsAssignmentId });
      }

      setAssessment({ ...assessmentData, assignmentId: assessmentData.eolsAssignmentId });
      setAssignmentType(assessmentData.assignmentType);

      return { data: { assignmentType: assessmentData.assignmentType, simChartId: assessmentData.simChartId } };
    }, null);
  };

  toggleNavigation = () => this.setState((prevState) => ({ openNavigation: !prevState.openNavigation }));

  render() {
    if (!this.state.isDataLoaded) {
      return null;
    }
    const { showSubmitAssessmentButton, showChartingDateTime, showPatientRibbon, showNavigation, isAuthor, assessment } = this.props;
    const { isDemoMode, isInProgressGradingEnabled, openNavigation, filteredShownMenuItems } = this.state;
    const isAssessmentGradable = assignmentHelper.isAssessmentSubmitted(assessment?.status);
    const isAssessmentStarted = assessment?.status !== AssessmentStatus.NOT_STARTED;

    return (
      <div className="page-wrapper">
        <PageHeader title={this.props.title} showWarningOnClose>
          {showSubmitAssessmentButton && !isAssessmentGradable && (
            <Box pr className="o-els-flex-layout__item">
              <AssessmentSubmission assessmentId={this.props.assessment?.eolsAssessmentId} />
            </Box>
          )}
        </PageHeader>
        {showChartingDateTime && (
          <div className="page-wrapper__assignment-bar-wrapper">
            <AssignmentBar toggleFlyout={this.props.toggleFlyout} />
          </div>
        )}
        {!isAuthor && showPatientRibbon && <PatientRibbon />}

        <ELSFlex className="page-wrapper__nav-and-content">
          <ELSFlexItem className="u-els-background-color-n0">
            {!showNavigation && <div className="nav--empty" />}
            {showNavigation && filteredShownMenuItems?.length > 0 && <Navigation show={openNavigation} items={filteredShownMenuItems} />}
          </ELSFlexItem>
          <ELSFlexItem grow className={cx('page-wrapper__content-wrapper', { 'u-els-margin-top-2x': !showNavigation })}>
            <div className={cssHelper.mapElsCss({ els: 'padding-left-3x padding-right-3x', md: 'padding-left-1x1o2 padding-right-1x1o2', lg: 'padding-left-2x padding-right-2x' })}>
              {showNavigation && (
                <div className="page-wrapper__breadcrumbs-bar">
                  <ELSButton id="nav-toggle" onClick={this.toggleNavigation} type="debuttonize" ariaLabel="Collapse/Expand Navigation" ariaExpanded={openNavigation}>
                    {openNavigation && <ELSIcon name="nav-collapse" prefix="hmds" color="n9" />}
                    {!openNavigation && <ELSIcon name="nav-expand" prefix="hmds" color="n9" />}
                  </ELSButton>
                  <Breadcrumb items={filteredShownMenuItems} />
                </div>
              )}
              <div className="page-wrapper__children-wrapper" id="children-wrapper">
                {(this.props.selectedNavId || this.props.isPlaceHolder) &&
                  this.props.children({
                    loggedInUser: this.props.loggedInUser,
                    userRole: this.props.userRole,
                    locale: this.props.locale
                  })}
              </div>
              <Box mt2>
                <ELSFooter />
              </Box>
            </div>
          </ELSFlexItem>
        </ELSFlex>

        {!isAuthor && ((isInProgressGradingEnabled && isAssessmentStarted && !isDemoMode) || isAssessmentGradable) && (
          <GradingChart isInProgressGradingEnabled={isInProgressGradingEnabled} />
        )}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  getMenuItems: (assessmentId: number, patientContext: PatientContext, skipCache?: boolean) => dispatch(appActions.fetchMenuItems(assessmentId, patientContext, skipCache)),
  setAssessment: (assessment) => dispatch(appActions.setAssessment(assessment)),
  setAssignment: (assignment) => dispatch(studentActions.setAssignment(assignment)),
  setAssignmentType: (assignmentType) => dispatch(studentActions.setAssignmentType(assignmentType)),
  setChartingTime: (dateTime) => dispatch(studentActions.setChartingTime(dateTime)),
  setPatientContext: (patientContext: PatientContext) => dispatch(appActions.setPatientContext(patientContext)),
  fetchPatientContext: (simChartAssessmentId, assigmentType: AssignmentType) => dispatch(appActions.fetchPatientContext(simChartAssessmentId, assigmentType)),
  setSkipLinkSections: (skipLinkSections: SkipLinkSection[]) => dispatch(appActions.setSkipLinkSections(skipLinkSections)),
  setPhaseIndex: (phaseIndex) => dispatch(studentActions.setPhaseIndex(phaseIndex)),
  setSelectedNavId: (navId) => dispatch(appActions.setSelectedNavId(navId)),
  setTimeSpentData: (timeSpentData) => dispatch(appActions.setTimeSpentData(timeSpentData))
});

const mapStateToProps = (state) => ({
  loggedInUser: appSelectors.getLoggedInUser(state),
  userRole: appSelectors.getUserRole(state),
  menuItems: appSelectors.getMenuItems(state),
  assignment: studentSelectors.getAssignment(state),
  assessment: appSelectors.getAssessment(state),
  selectedNavId: appSelectors.getNavId(state),
  isAuthor: appSelectors.getIsAuthor(state),
  phaseIndex: studentSelectors.getPhaseIndex(state),
  locale: appSelectors.getLocale(state),
  assignmentType: studentSelectors.getAssignmentType(state),
  fullAssignment: studentSelectors.getFullAssignment(state)
});

const enhancers = [withRouter, ELSWithModalService, connect(mapStateToProps, mapDispatchToProps)];
export { PageWrapper as BasePageWrapper };
export default compose(...enhancers)(PageWrapper);
