import { delay } from 'lodash';
import { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { ChartFragment } from 'models/api-response';
import { ELSModalServiceType } from 'models/els';
import { ChartActionsComponentProps, ChartComponentProps, ChartMetaFormField } from 'models/ui';
import { AppConstant, Locales, NAV_ID, REDIRECT_DELAY_TIME, RoutePath } from 'constants/app.constant';
import { appHelper, chartHelper, dateTimeHelper } from 'helpers';
import { getEmptyEhrDemo, isDemoEmptyEhr } from 'helpers/assignment.helper';
import { chartService, featureService, navigationService } from 'services';
import { appSelectors } from 'redux/ducks/app';
import { instructorActions } from 'redux/ducks/instructor';
import { ConfirmationModal, withChartLogic } from 'components/common';
import ClinicalSetupView, { ClinicalSetupViewProps } from 'components/features/chart/clinical-setup/ClinicalSetupView';
import { FormField, getClinicalSetupFormFieldBuilderItems } from './constants';

export interface ClinicalSetupProps extends ChartComponentProps {
  modalService: ELSModalServiceType;
  isAuthor: boolean;
  userRole: string;
  setEmptyEhrDemo: Function;
  locale: Locales;
}

interface ClinicalSetupState {
  fragment: ChartFragment;
  isShowClinicalUnit: boolean;
  isInDemoEmptyEhr?: boolean;
  instructorDemoData?: InstructorDemoDataProps;
}

interface InstructorDemoDataProps {
  [FormField.CLINICAL_FIRST_DAY]: string;
  [FormField.CLINICAL_UNIT]?: {
    choices: string;
  };
  [FormField.PATIENT_IDENTIFIER]: string;
  [FormField.PATIENT_SEX]: string;
  [FormField.PATIENT_DOB]: string;
  [FormField.PROVIDER_INFO]: string;
  [FormField.PROVIDER_FIRST_LAST_INITIAL]: string;
  [FormField.PATIENT_NAME]?: string;
  [FormField.MRN]?: string;
  [FormField.ROOM_NUMBER]?: string;
}

class ClinicalSetup extends Component<ClinicalSetupProps, ClinicalSetupState> {
  static displayName = 'ClinicalSetup';

  constructor(props) {
    super(props);

    this.state = {
      fragment: null,
      isShowClinicalUnit: false,
      isInDemoEmptyEhr: isDemoEmptyEhr(),
      instructorDemoData: getEmptyEhrDemo()?.patientInfo
    };
  }

  componentDidMount() {
    const loadChartDataPromise = this.props.loadChartData().then(({ data: fragments }) => {
      const fragment = chartHelper.findFragmentByNavId(fragments, NAV_ID.PATIENT);
      if (fragment) {
        this.setState({ fragment });
      }
    });

    const loadIsShowClinicalUnitPromise = featureService.isShowClinicalUnit().then((isShowClinicalUnit) => this.setState({ isShowClinicalUnit }));

    return appHelper.useLoader(
      Promise.all([loadChartDataPromise, loadIsShowClinicalUnitPromise]).finally(() => this.props.initState(this.buildDefaultFormFields())),
      { errorMessage: 'can not load patient' }
    );
  }

  generateChartData = () => {
    const { formFieldMap, locale } = this.props;
    const clinicalUnitChoices = this.state.isShowClinicalUnit
      ? formFieldMap
          .get(FormField.CLINICAL_UNIT)
          .chartContent?.filter((item) => item.selected)
          .map((item) => ({ value: item.value, id: item.id }))
      : [];
    return {
      clinicalFirstDay: dateTimeHelper.toISO(formFieldMap.get(FormField.CLINICAL_FIRST_DAY).value, locale),
      clinicalUnit: {
        choices: clinicalUnitChoices
      },
      patientIdentifier: formFieldMap.get(FormField.PATIENT_IDENTIFIER).value,
      patientSex: formFieldMap.get(FormField.PATIENT_SEX).value,
      patientDateOfBirth: dateTimeHelper.toISO(formFieldMap.get(FormField.PATIENT_DOB).value, locale),
      providerFirstLastInitial: formFieldMap.get(FormField.PROVIDER_FIRST_LAST_INITIAL).value,
      patientName: formFieldMap.get(FormField.PATIENT_NAME).value,
      mrn: formFieldMap.get(FormField.MRN).value,
      roomNumber: formFieldMap.get(FormField.ROOM_NUMBER).value,
      gender: formFieldMap.get(FormField.GENDER).value,
      specifyBelow: formFieldMap.get(FormField.SPECIFY_BELOW).value,
      pronouns: formFieldMap.get(FormField.PRONOUNS).value
    };
  };

  buildFragment = () => {
    const basicInfo = chartService.createBaseFragment({ fragmentId: this.state.fragment?.fragmentId, chartingTime: this.props.chartingTime });
    const chartData = this.generateChartData();
    return { ...basicInfo, chartData };
  };

  navigateToSummary = () => navigationService.navigateToChart(RoutePath.student.summary, this.props.assessment.eolsAssessmentId);

  handleSaveClick = () => {
    if (this.state.isInDemoEmptyEhr) {
      const emptyEhrDemo = getEmptyEhrDemo();
      emptyEhrDemo.patientInfo = this.generateChartData();
      this.props.setEmptyEhrDemo(emptyEhrDemo);
      sessionStorage.setItem(AppConstant.IS_DEMO_EHR_NAVIGATED, 'true');
      delay(this.navigateToSummary, REDIRECT_DELAY_TIME);
    } else {
      this.props.saveChart([this.buildFragment()], { defaultFormFields: this.buildDefaultFormFields(), beforeSave: this.handleStartChartingClick, afterSave: this.afterSave });
    }
  };

  afterSave = () => {
    const { isAuthor } = this.props;
    if (!isAuthor) {
      delay(this.navigateToSummary, REDIRECT_DELAY_TIME);
    }
  };

  handleStartChartingClick = () =>
    new Promise<void>((resolve, reject) => {
      const { formFieldMap, modalService } = this.props;

      if (chartHelper.chartHasErrors(formFieldMap)) {
        appHelper.scrollTo('#error-card');
        reject();
        return;
      }

      // if this.props.formSubmittedCount and the form has errors
      // scroll to the top, or just return early
      // else pop the Start Charting Modal
      const modalId = 'startChartingModalId';
      modalService.openModal({
        modalId,
        content: (
          <ConfirmationModal
            message="Please ensure your patient data is correct before continuing."
            okButtonText="Continue"
            cancelButtonText="Edit"
            onOkClick={() => {
              modalService.closeModal(modalId);
              resolve();
            }}
            onCancelClick={() => {
              modalService.closeModal(modalId);
              reject();
            }}
          />
        )
      });
    });

  buildDefaultFormFields = (): Map<string, ChartMetaFormField> => {
    const { locale, intl } = this.props;
    const patientData = this.state.isInDemoEmptyEhr ? this.state.instructorDemoData : this.state.fragment?.chartData;
    const { createFormField } = chartHelper;
    const dataMap = new Map();

    getClinicalSetupFormFieldBuilderItems(locale, this.state.isShowClinicalUnit, patientData).forEach(({ isHidden, label, ...item }) => {
      if (!isHidden) {
        dataMap.set(item.name, createFormField({ ...item, label: label && intl.formatMessage({ id: label }) }));
      }
    });

    return dataMap;
  };

  render() {
    const { fragment } = this.state;
    const { formFieldMap } = this.props;
    const chartActionsProps: ChartActionsComponentProps = {
      onSaveClick: this.handleSaveClick,
      onCancelClick: () => this.props.handleDiscardClick(() => this.props.initState(this.buildDefaultFormFields())),
      onDisplayRecordsClick: this.props.displayAuthoringData,
      enableSaveButton: true,
      saveButtonText: 'Start Charting',
      enableDisplayRecordsButton: this.props.enableDisplayRecordsButton,
      isDemoEmptyEhrClinicalSetup: this.state.isInDemoEmptyEhr
    };

    const viewProps: ClinicalSetupViewProps = {
      fragment,
      chartMetaFormFields: formFieldMap,
      formSubmittedCount: this.props.formSubmittedCount,
      patientIdentifierAvatar: formFieldMap?.has(FormField.PATIENT_IDENTIFIER) ? formFieldMap.get(FormField.PATIENT_IDENTIFIER).value.substring(0, 2) : '',
      studentFirstInitial: this.props.assessment?.firstName?.substring(0, 1) || '',
      studentLastName: this.props?.assessment?.lastName || '',
      isShowClinicalUnit: this.state.isShowClinicalUnit,
      isAuthor: this.props.isAuthor,
      chartActionsProps
    };

    return <ClinicalSetupView {...viewProps} />;
  }
}

const mapStateToProps = (state) => ({
  isAuthor: appSelectors.getIsAuthor(state),
  locale: appSelectors.getLocale(state),
  userRole: appSelectors.getUserRole(state)
});

const mapDispatchToProps = (dispatch) => ({
  setEmptyEhrDemo: (payload) => dispatch(instructorActions.setEmptyEhrDemo(payload))
});

export { ClinicalSetup as BaseClinicalSetup };
export default compose(withChartLogic, connect(mapStateToProps, mapDispatchToProps), injectIntl)(ClinicalSetup);
