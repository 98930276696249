import { isEmpty } from 'lodash';
import { Component } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { compose } from 'recompose';
import { Banner } from '@els/els-react--banner';
import { Pill } from '@els/els-react--pill';
import { AssessmentRS, TimeSpentRS } from 'models/api-response';
import { AssessmentStatus, AssignmentType, GradingOption, GradingResult } from 'models/enum';
import { AnswerSubmission, ChartComment, NavigationItem, NewAssignment, PatientContext, SimChartAssignment, SkipLinkSection } from 'models/ui';
import { Locales } from 'constants/app.constant';
import { apiHelper, appHelper, assignmentHelper } from 'helpers';
import { isAssessmentGradingAllowed, isAssessmentSubmitted } from 'helpers/assignment.helper';
import { formatDate, getMostRecentDateTimeStamp, toMomentWithParsers } from 'helpers/datetime.helper';
import { assignmentService, chartService, navigationService } from 'services';
import { appActions, appSelectors } from 'redux/ducks/app';
import { studentSelectors } from 'redux/ducks/student';
import { AllowEvent, Box, ButtonLinkIcon, withFormUtilities } from 'components/common';
import { AssessmentStatistic } from 'components/common/assessment-statistic/AssessmentStatistic';
import ChartCommentList from 'components/common/chart-comment-list/ChartCommentList';
import { ELSButton, ELSFlex, ELSFlexItem, ELSIcon, ELSPageLoader, ELSTooltip } from 'components/common/els';
import Feedback from './Feedback';
import NotGradedGrade from './NotGradedGrade';
import PassFailGrade from './PassFailGrade';
import ScoreGrade from './ScoreGrade';
import './assignment-submission.scss';
import { QuizQuestionsResult } from '../quiz-questions-result/QuizQuestionsResult';
import { ELSModalServiceType } from 'models/els';

interface AssignmentSubmissionProps {
  getMenuItems: Function;
  setAssessment: Function;
  menuItems: NavigationItem[];
  fetchPatientContext: Function;
  setSkipLinkSections: Function;
  fullAssignment: SimChartAssignment;
  assignment: NewAssignment;
  assignmentId: number;
  locale: Locales;
  isInProgressGradingEnabledFlag?: boolean;
  showConfirmationModal: Function;
  showSuccessModal: Function;
  assessment: AssessmentRS;
  hideSuccessModal: Function;
  modalService: ELSModalServiceType;
  setIsAssignmentFromSubmission: Function;
}

export interface AssignmentSubmissionState {
  totalCorrect?: number;
  totalQuestions?: number;
  totalIncorrect?: number;
  simChartId: string;
  title: string;
  assignmentType: string;
  assessmentId: string;
  eolsAssignmentId: string;
  gradeType?: GradingOption;
  gradeStatus?: GradingResult;
  score?: number;
  startedAt: string;
  completedAt: string;
  durationTime: number;
  completedCharts: string[];
  status: AssessmentStatus;
  overallComment: { comment: string; createdAt: string };
  isFeedback?: boolean;
  feedback?: string | null | boolean;
  chartComments: ChartComment[];
  dueDate: string;
  questions: AnswerSubmission[];
  timeSpent: TimeSpentRS;
  isBannerVisible: boolean;
}

interface MatchParams {
  assessmentId: string;
}

class AssignmentSubmission extends Component<AssignmentSubmissionProps & RouteComponentProps<MatchParams>, AssignmentSubmissionState> {
  constructor(props) {
    super(props);
    this.state = {
      totalCorrect: null,
      totalQuestions: null,
      totalIncorrect: null,
      simChartId: '',
      title: '',
      assignmentType: '',
      assessmentId: null,
      eolsAssignmentId: null,
      gradeType: null,
      gradeStatus: null,
      score: null,
      startedAt: '',
      completedAt: '',
      durationTime: 0,
      completedCharts: [],
      status: null,
      overallComment: null,
      isFeedback: null,
      chartComments: [],
      dueDate: '',
      questions: [],
      timeSpent: null,
      isBannerVisible: true
    };
  }

  async componentDidMount() {
    await this.loadData();
  }

  componentDidUpdate(prevProps, prevState) {
    const { assessmentId, simChartId } = this.state;
    const { menuItems } = this.props;

    if ((prevState.simChartId !== simChartId || prevProps.menuItems !== menuItems) && menuItems?.length) {
      chartService
        .buildChartComments(assessmentId, simChartId, menuItems, this.state.completedCharts)
        .then((chartComments) => this.setState({ chartComments }))
        .catch((err) => apiHelper.showApiError(`can not load chart comments ${err}`));
    }
    
    if(this.props.isInProgressGradingEnabledFlag && prevState.chartComments.length !== this.state.chartComments.length && this.checkForChartComments(this.state.chartComments)) {
      this.renderChartNotifications();
    }
  }

  checkForChartComments(chartComments: ChartComment[]) {
    return chartComments?.find(comment => comment.contents?.length > 0);
  }

  renderOverallComment = () => {
    const { overallComment, status } = this.state;
    const { locale, isInProgressGradingEnabledFlag } = this.props;
    if (status === AssessmentStatus.PAST_DUE) {
      return (
        <ELSFlex className="assignment-submission__overall-comments">
          <ELSFlexItem>
            <ELSIcon prefix="gizmo" name="information" customClass="assignment-submission--information-hollow" size="1x" />
          </ELSFlexItem>
          <ELSFlexItem>
            <div>
              There is no instructor feedback, since your assignment wasn’t submitted by the due date and time. If you’d like to get instructor feedback, you have the option to
              submit the assignment late. However, please note that you will not receive a revised grade for the assignment.
            </div>
          </ELSFlexItem>
        </ELSFlex>
      );
    }
    return overallComment?.comment ? (
      <div className="u-els-display-flex">
        {isInProgressGradingEnabledFlag && <Pill
          label={formatDate({
            date: toMomentWithParsers(overallComment.createdAt).toDate(),
            locale,
            includeTime: true
          })}
          className="u-els-color-black u-els-background-color-n6 u-els-margin-right u-els-height-1o1"
        />}
        <p className="assignment-submission__overall-comments">{overallComment.comment}</p>
      </div>
    ) : (
      <p className="assignment-submission__overall-comments">
        <ELSIcon prefix="gizmo" name="information" customClass="assignment-submission--information-hollow" size="1x" />
        Your instructor has not yet submitted any feedback. Please check back later.
      </p>
    );
  };

  renderGradeInfo = () => {
    const { gradeType, score, gradeStatus, dueDate, status } = this.state;
    const { locale } = this.props;

    const isCompleted = status === AssessmentStatus.COMPLETED;
    const formattedDueDate = formatDate({ date: toMomentWithParsers(dueDate).toDate(), locale });

    switch (gradeType) {
      case GradingOption.PASS_FAIL:
        return (
          <PassFailGrade
            className="u-els-width-1o2 u-els-width-1o1@tablet"
            isPassed={gradeStatus === GradingResult.PASS}
            formattedDueDate={formattedDueDate}
            assessmentStatus={status}
          />
        );
      case GradingOption.SCORED:
        return (
          <ScoreGrade className="u-els-width-1o2 u-els-width-1o1@tablet" score={score} formattedDueDate={formattedDueDate} isCompleted={isCompleted} assessmentStatus={status} />
        );
      case GradingOption.NOT_GRADED:
        return <NotGradedGrade className="u-els-width-1o2 u-els-width-1o1@tablet" formattedDueDate={formattedDueDate} isCompleted={isCompleted} assessmentStatus={status} />;
      default:
        return null;
    }
  };

  getAssignment = async (assignmentId) => {
    if (isEmpty(this.props.fullAssignment)) {
      console.log('getSimChartAssignment getAssignment', this.props.fullAssignment);
      try {
        const responseAssignment = await assignmentService.getSimChartAssignment(Number(assignmentId));
        return responseAssignment.data;
      } catch (error) {
        return error;
      }
    }

    return this.props.fullAssignment;
  };

  loadData = async () => {
    const { assessmentId } = this.props.match.params;
    const { assignmentId, getMenuItems, fetchPatientContext, setSkipLinkSections, setAssessment } = this.props;

    const timeSpentRS = await assignmentService.getTimeSpent(assignmentId);
    const assessmentRS = await assignmentService.getAssessment(Number(assessmentId));

    const assignmentRS = await this.getAssignment(assessmentRS.data.eolsAssignmentId);
    const submissionRS = await assignmentService.getAssessmentSubmissionSummary(assessmentRS.data.eolsAssignmentId, assessmentRS.data.studentId);

    const {
      simChartId,
      assignmentType,
      assignmentGradeType,
      assignmentTitle,
      gradeStatus,
      startedAt,
      completedAt,
      durationTime,
      caseStudySubmissionSummary,
      completedCharts,
      status,
      feedback
    } = submissionRS.data;

    const patientContext = await fetchPatientContext(assessmentRS.data.simChartId, assignmentType);
    await getMenuItems(assessmentRS.data.eolsAssessmentId, patientContext);
    const overallChartCommentRS = await chartService.getOverallComment(simChartId);
    const overallComment: AssignmentSubmissionState['overallComment'] = {
      comment: overallChartCommentRS?.chartData?.comment || '',
      createdAt: overallChartCommentRS?.createdAt
    };
    const { totalCorrect, totalQuestions, sequenceChunkSummaries } = caseStudySubmissionSummary ?? {};
    const totalIncorrect = this.props.isInProgressGradingEnabledFlag ? assignmentHelper.getIncorrectQuestions(sequenceChunkSummaries) : totalQuestions - totalCorrect;
    const score = assignmentGradeType === GradingOption.SCORED ? assignmentHelper.getScore(totalCorrect, totalQuestions, status) : null;

    setSkipLinkSections();
    setAssessment({ ...assessmentRS.data, assignmentId: assessmentRS.data.eolsAssignmentId, id: assessmentRS.data.eolsAssessmentId });

    this.setState({
      totalCorrect,
      totalQuestions,
      totalIncorrect, 
      simChartId,
      assignmentType,
      gradeType: assignmentGradeType,
      title: `${assignmentTitle} - Submission`,
      timeSpent: timeSpentRS.data.find((timeSpent: TimeSpentRS) => timeSpent.assessmentId === Number(assessmentId)),
      gradeStatus,
      startedAt,
      completedAt,
      durationTime,
      score,
      completedCharts,
      status,
      overallComment,
      isBannerVisible: status === AssessmentStatus.IN_PROGRESS && overallComment && !!overallComment?.comment,
      assessmentId: assessmentRS.data.eolsAssessmentId,
      eolsAssignmentId: assessmentRS.data.eolsAssignmentId,
      isFeedback: feedback,
      dueDate: assignmentRS.dueDate,
      questions:
        sequenceChunkSummaries
          ?.flatMap((chunk) => chunk.stepChunks)
          .map((step) => ({
            ...step.content,
            correct: step.correct,
            selectedAnswers: step.selectedAnswers
          })) ?? []
    });
  };

  handleViewEHRChart = () => {
    const { chartComments } = this.state;
    const { history } = this.props;

    if (chartComments?.length) {
      this.props.setIsAssignmentFromSubmission(true); // setting it to true. so, navigating to clinical setup from submission will show Back to Submission Link
      const { path } = chartComments[0];
      history.push(path);
    } else {
      this.navigateToStartingPage();
    }
  };

  navigateToStartingPage = () => {
    const { assignmentType, assessmentId, eolsAssignmentId } = this.state;
    this.props.setIsAssignmentFromSubmission(true); // setting it to true. so, navigating to clinical setup from submission will show Back to Submission Link
    navigationService.navigateToAssignmentStartingPage(assignmentType, eolsAssignmentId, assessmentId);
  };

  renderButtonLinkIcon = (isDisabled: boolean) => (
    <ButtonLinkIcon
      className="u-els-display-inline-block u-els-font-size-base u-els-margin-left assignment-submission__link-chart-btn"
      iconName="arrow-right"
      iconPosition="right"
      iconSize="1x"
      text="View EHR Charts"
      onClick={this.handleViewEHRChart}
      isDisabled={isDisabled}
    />
  );

  handleViewSpecificChart = (path: string) => this.props.history.push(path);

  handleStudentDismissFeedbackBanner = () => this.setState({ isBannerVisible: false });

  handleResumeAssignment = () => {
    this.navigateToStartingPage();
  };

  handleSubmitAssignment = () => {
    const { showConfirmationModal } = this.props;

    showConfirmationModal({
      showIcon: true,
      header: 'Please confirm.',
      message: 'Are you sure that you want to submit? Once completed, no further changes can be made. You will lose any unsaved work.',
      onOkClick: this.handleConfirmSubmission
    });
  };

  handleConfirmSubmission = () => {
    const { showSuccessModal, assessment } = this.props;
    const assessmentId = assessment?.eolsAssessmentId;

    appHelper.useLoader(
      assignmentService.submitAssessment(assessmentId).then(() =>
        showSuccessModal({
          showClose: false,
          message: 'Assignment submitted for grading',
          actions: [{ text: 'View Completed Assignment', onClick: this.handleCompletedAssignment }]
        })
      )
    );
  };

  handleCompletedAssignment = () => {
    this.props.hideSuccessModal();
    appHelper.useLoader(this.loadData());
  }

  renderChartNotifications = () => {
    const {modalService} = this.props;
    const modalId = 'alertnotification';
    modalService.openModal({
      modalId: modalId,
      color: 'primary',
      content: (
        <>
          <h2 className="u-els-font-size-h2 u-els-margin-bottom">Notifications</h2>
          <div>
            <p className="u-els-margin-bottom-1o2">Your instructor has left feedback on the below Charts:</p>
            <ul className="c-els-list">
              {this.state.chartComments?.map(comment => (
               comment.contents?.length > 0 ? <li key={comment.id} className="c-els-list__item">{comment.title}</li> : null
              ))}
            </ul>
          </div>
        </>
      )
    });
  }

  render() {
    const {
      totalCorrect,
      totalQuestions,
      totalIncorrect,
      assignmentType,
      title,
      gradeType,
      gradeStatus,
      score,
      startedAt,
      completedAt,
      durationTime,
      completedCharts,
      status,
      isFeedback,
      chartComments,
      questions,
      assessmentId,
      timeSpent,
      overallComment
    } = this.state;
    const { locale, isInProgressGradingEnabledFlag } = this.props;
    const commentCount = chartComments.reduce((result, current) => (current.contents?.length > 0 ? result + current.contents.length : result), 0);
    const isGradable = isAssessmentGradingAllowed(isInProgressGradingEnabledFlag, status);
    const isSubmitted = isAssessmentSubmitted(status);
    const dateTimeStampsForChartComments = chartComments.map((comment) => comment?.createdAt);
    const dateTimeStampForMostRecentComment = getMostRecentDateTimeStamp([overallComment?.createdAt, ...dateTimeStampsForChartComments]);

    if (!gradeType) return <ELSPageLoader />;
    return (
      <div className="assignment-submission">
        <Banner isVisible={this.state.isBannerVisible} isIconVisible={false} onClose={this.handleStudentDismissFeedbackBanner} hideCloseLabel>
          Your instructor has left feedback on this in-progress assignment.
        </Banner>

        <header className="u-els-padding-1x1o2 u-els-padding-left-2x u-els-padding-right-3x">
          <h1 className="u-els-display-inline-block">{title}</h1>
          {isAssessmentSubmitted(status) && (
            <ELSTooltip
              wrapperClassName={`tooltip-overlay-header ${completedCharts.length > 0 && 'u-els-display-none'}`}
              content="View EHR Charts is disabled because no charts were filled out"
              showWhenHover
              showClose
            >
              {completedCharts.length === 0 ? <AllowEvent>{this.renderButtonLinkIcon(true)}</AllowEvent> : this.renderButtonLinkIcon(false)}
            </ELSTooltip>
          )}
        </header>
        <Box className="assignment-submission__hightlighted-result" px3>
          {this.renderGradeInfo()}
          <Feedback
            className="u-els-width-1o2 u-els-width-1o1@tablet"
            submittedTime={isGradable ? dateTimeStampForMostRecentComment : completedAt}
            isPastDue={status === AssessmentStatus.PAST_DUE}
            isFeedback={isFeedback}
            commentCount={commentCount}
            locale={locale}
            isInProgressGradingEnabledFlag={isInProgressGradingEnabledFlag}
            hasOverallComment={!!overallComment?.comment}
          />
        </Box>
        <Box px3 py2>
          <h2>Instructor Overall Comments</h2>
          {this.renderOverallComment()}
        </Box>
        {isGradable && (
          <Box px3 py2>
            <ChartCommentList
              title="Instructor Chart Comments"
              chartComments={chartComments}
              onViewChartClick={this.handleViewSpecificChart}
              timeSpent={timeSpent?.charts}
              locale={locale}
            />
          </Box>
        )}

        {isAssessmentSubmitted(status) && assignmentType === AssignmentType.CASE_STUDY && <QuizQuestionsResult questions={questions} totalIncorrect={totalIncorrect} totalCorrect={totalCorrect} />}

        {!isSubmitted && isInProgressGradingEnabledFlag ? (
          <ELSFlex className="sng-form-buttons u-els-padding-left-2x" left wrap>
            <ELSFlexItem>
              <ELSButton text="Resume Assignment" isDisabled={false} onClick={this.handleResumeAssignment} />
            </ELSFlexItem>
            {assignmentType !== AssignmentType.CASE_STUDY && <ELSFlexItem>
              <ELSButton type="secondary" text="Submit Assignment" isDisabled={false} onClick={this.handleSubmitAssignment} />
            </ELSFlexItem>}
          </ELSFlex>
        ) : null}

        <AssessmentStatistic
          className="u-els-margin-top-2x"
          gradeType={gradeType}
          gradeStatus={gradeStatus}
          score={assignmentHelper.getPrettyScore(score)}
          startedAt={startedAt}
          completedAt={completedAt}
          durationTime={durationTime}
          completedChartsLength={completedCharts.length}
          assessmentId={Number(assessmentId)}
          timeSpentData={timeSpent}
          locale={locale}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  menuItems: appSelectors.getMenuItems(state),
  assignmentId: studentSelectors.getFullAssignment(state)?.eolsAssignmentId,
  assessment: appSelectors.getAssessment(state),
  locale: appSelectors.getLocale(state),
  fullAssignment: studentSelectors.getFullAssignment(state),
  assignment: studentSelectors.getAssignment(state),
  isInProgressGradingEnabledFlag: appSelectors.getIsInProgressGradingEnabledFlag(state)
});

const mapDispatchToProps = (dispatch) => ({
  getMenuItems: (assessmentId: number, patientContext: PatientContext) => dispatch(appActions.fetchMenuItems(assessmentId, patientContext)),
  fetchPatientContext: (simChartAssessmentId: string, assignmentType: AssignmentType) => dispatch(appActions.fetchPatientContext(simChartAssessmentId, assignmentType)),
  setSkipLinkSections: (skipLinkSections: SkipLinkSection[]) => dispatch(appActions.setSkipLinkSections(skipLinkSections)),
  setAssessment: (assessment) => dispatch(appActions.setAssessment(assessment)),
  setIsAssignmentFromSubmission: (payload) => dispatch(appActions.setIsAssignmentFromSubmission(payload))
});

export { AssignmentSubmission as BaseAssignmentSubmission };
export default compose(withRouter, withFormUtilities, connect(mapStateToProps, mapDispatchToProps))(AssignmentSubmission);
