import { Component } from 'react';
import { IntlShape, injectIntl } from 'react-intl';
import { compose } from 'recompose';
import { ChartWrapper, PageLoader, withHTMLHeadSEO } from 'components/common';
import { Flyout, PageWrapper } from 'components/common/layout';
import './with.chart.layout.scss';

export interface WithChartLayoutProps {
  intl: IntlShape;
}

export interface WithChartLayoutState {
  isFlyoutOpen: boolean;
  flyoutContent: JSX.Element | null;
}

const withChartLayout = (pageTitle: string) => (WrappedComponent) => {
  class ChartLayout extends Component<WithChartLayoutProps, WithChartLayoutState> {
    constructor(props) {
      super(props);
      this.state = {
        isFlyoutOpen: false,
        flyoutContent: null
      };
    }

    toggleFlyout = (content: JSX.Element | null = null) => {
      this.setState((state) => ({
        ...state,
        flyoutContent: content,
        isFlyoutOpen: !state.isFlyoutOpen
      }));
    };

    render() {
      return (
        <div className="flex-container">
          <PageLoader />
          <div className="chart">
            <PageWrapper showChartingDateTime toggleFlyout={this.toggleFlyout}>
              {({ loggedInUser, locale }) => (
                <ChartWrapper>
                  {({
                    pushUnsavedChangesList,
                    selectedNavId,
                    assessment,
                    chartingTime,
                    handleNavigationAttempt,
                    loadChartData,
                    saveChartData,
                    deleteChartData,
                    saveMultiFragments,
                    displayAuthoringData,
                    isAuthor,
                    enableDisplayRecordsButton,
                    patientContext,
                    enableMultiStepsAuthoring,
                    onEnableDisplayRecords,
                    backToSourceLocation
                  }) => (
                    <WrappedComponent
                      pushUnsavedChangesList={pushUnsavedChangesList}
                      student={loggedInUser}
                      selectedNavId={selectedNavId}
                      handleNavigationAttempt={handleNavigationAttempt}
                      toggleFlyout={this.toggleFlyout}
                      assessment={assessment}
                      chartingTime={chartingTime}
                      loadChartData={loadChartData}
                      saveChartData={saveChartData}
                      deleteChartData={deleteChartData}
                      saveMultiFragments={saveMultiFragments}
                      displayAuthoringData={displayAuthoringData}
                      isAuthor={isAuthor}
                      enableDisplayRecordsButton={enableDisplayRecordsButton}
                      patientContext={patientContext}
                      enableMultiStepsAuthoring={enableMultiStepsAuthoring}
                      onEnableDisplayRecords={onEnableDisplayRecords}
                      backToSourceLocation={backToSourceLocation}
                      locale={locale}
                      intl={this.props.intl}
                    />
                  )}
                </ChartWrapper>
              )}
            </PageWrapper>
          </div>
          <Flyout isOpen={this.state.isFlyoutOpen} content={this.state.flyoutContent} closeFlyout={this.toggleFlyout} />
        </div>
      );
    }
  }

  const enhancers = [withHTMLHeadSEO({ title: pageTitle }), injectIntl];
  return compose(...enhancers)(ChartLayout);
};

export default withChartLayout;
