import produce from 'immer';
import moment from 'moment';
import queryString from 'query-string';
import { Component } from 'react';
import { connect } from 'react-redux';
import { Assignment } from 'models/api-response';
import { AssignmentStatus, AssignmentType, LinkingAction, UserRole } from 'models/enum';
import { DropDownItem, SimChartAssignment } from 'models/ui';
import { apiHelper, appHelper } from 'helpers';
import { appService, assignmentService, featureService } from 'services';
import { appActions, appSelectors } from 'redux/ducks/app';
import { studentActions } from 'redux/ducks/student';
import LaunchView, { LaunchViewProps } from './LaunchView';
import { AppConstant } from 'constants/app.constant';

export interface LaunchProps {
  isAuthor: boolean;
  courseId: number;
  userRole: string;
  loggedInUserId: number;
  setAssignment: Function;
  setAssignmentType: Function;
  setLocale: Function;
  setFullAssignment: Function;
  setIsInProgressGradingEnabledFlag: Function;
  setIsAssignmentFromSubmission: Function;
}

interface LaunchState {
  isLoading: boolean;
  actionDropDownItems: DropDownItem[];
  selectedAction: LinkingAction;
  includeBlankChart: boolean;
  includeCaseStudy: boolean;
  includePastDue: boolean;
  assignments: Assignment[];
  filteredAssignments: Assignment[];
  selectedAssignmentId: number;
}

class Launch extends Component<LaunchProps, LaunchState> {
  static displayName = 'Launch';

  constructor(props) {
    super(props);
    const actionDropDownItems = this.getActionsDropDownItems();

    this.state = {
      isLoading: false,
      assignments: [],
      filteredAssignments: [],
      actionDropDownItems,
      selectedAction: actionDropDownItems[0].value as LinkingAction,
      selectedAssignmentId: null,
      includeBlankChart: true,
      includeCaseStudy: true,
      includePastDue: true
    };
  }

  componentDidMount() {
    const { isAuthor, userRole } = this.props;

    // if user is an Instructor and an author,
    // add an action for authors
    if (userRole === UserRole.INSTRUCTOR && isAuthor) {
      const updatedActions = {
        actionDropDownItems: [{ name: LinkingAction.INSTRUCTOR_AUTHOR_LANDING_PAGE as string, value: LinkingAction.INSTRUCTOR_AUTHOR_LANDING_PAGE as string }].concat(
          this.state.actionDropDownItems
        ),
        selectedAction: LinkingAction.INSTRUCTOR_AUTHOR_LANDING_PAGE
      };
      this.setState({ ...updatedActions });
    } else {
      this.setState({ isLoading: true });
      this.getAssignments()
        .catch((err) => apiHelper.showApiError(`can not load assignments ${err}`))
        .finally(() => this.setState({ isLoading: false }));
    }
    this.setIsInProgressGradingFlag();
    localStorage.setItem(AppConstant.PHASE_ID, null); // re-setting here. so, when we land on summary page, new phase id will be set based on assessment.
    this.props.setIsAssignmentFromSubmission(true); // for local purpose, setting to default value
  }

  getAssignments = () =>
    assignmentService.getStudentAssignments(this.props.courseId).then(({ data: assignments }) => {
      if (!assignments?.length) return;

      const { userRole, loggedInUserId } = this.props;
      const modifiedAssignments = assignments
        .filter((item) => {
          if (userRole === UserRole.STUDENT) {
            return (item.students || []).includes(loggedInUserId);
          }
          return true;
        })
        .map((assignment, index) => ({
          ...assignment,
          selected: index === 0,
          status: moment().isSameOrAfter(assignment.dueDate) ? AssignmentStatus.PAST_DUE : AssignmentStatus.STARTED
        }));
      const selectedAssignmentId = assignments[0].eolsAssignmentId;
      this.setState(
        {
          selectedAssignmentId,
          assignments: modifiedAssignments,
          filteredAssignments: modifiedAssignments
        },
        () => userRole === UserRole.STUDENT && this.findAndSetAssignment(selectedAssignmentId)
      );
    });

  getActionsDropDownItems = (): DropDownItem[] => {
    let actions = [];
    switch (this.props.userRole) {
      case UserRole.STUDENT:
        actions = [LinkingAction.STUDENT_LANDING_PAGE, LinkingAction.STUDENT_ASSIGNMENT_ACCESS_CLINICAL, LinkingAction.STUDENT_LAUNCH_ASSIGNMENT];
        break;
      case UserRole.INSTRUCTOR:
        actions = [
          LinkingAction.INSTRUCTOR_GRADE_ASSIGNMENT_LANDING,
          LinkingAction.INSTRUCTOR_VIEW_ASSIGNMENT,
          LinkingAction.INSTRUCTOR_EDIT_ASSIGNMENT,
          LinkingAction.INSTRUCTOR_ADD_ASSIGNMENT
        ];
        break;
      default:
        actions = [];
    }
    return (actions || []).map((action) => ({ name: action, value: action }));
  };

  findAndSetAssignment = (assignmentId: number): Assignment => {
    const { assignments } = this.state;
    const selectedAssignment = assignments.find((item: Assignment) => item.eolsAssignmentId === assignmentId);
    if (selectedAssignment) {
      this.props.setAssignment({ assignmentId, title: selectedAssignment.title });
    }
    return selectedAssignment;
  };

  setFullAssignment = async (assignmentId) => {
      try {
        const responseAssignment = await assignmentService.getSimChartAssignment(Number(assignmentId));
        this.props.setFullAssignment(responseAssignment.data);
        return responseAssignment.data;
      } catch (error) {
        return error;
      }
  };

  setIsInProgressGradingFlag = async () => {
    try {
     const flagValue = await featureService.isInProgressGradingEnabled();
     this.props.setIsInProgressGradingEnabledFlag(flagValue);
    } catch (err) {
      return err;
    }
  };

  handleActionSelect = ({ target }) => {
    const { value } = target;
    this.setState({ selectedAction: value });
  };

  handleAssignmentRowSelect = (row) => {
    const assignmentId = row.eolsAssignmentId;
    this.setState(
      produce((draft: LaunchState) => {
        draft.filteredAssignments = draft.filteredAssignments.map((assignment) => ({ ...assignment, selected: assignment.eolsAssignmentId === assignmentId }));
        draft.selectedAssignmentId = assignmentId;
      }),
      () => this.findAndSetAssignment(assignmentId)
    );
  };

  handleAssignmentFilterChange = (fieldName: string, value: boolean) => {
    this.setState(
      produce((state: LaunchState) => {
        state[fieldName] = value;
        let filteredAssignments = state.assignments;
        if (!state.includeBlankChart) {
          filteredAssignments = filteredAssignments.filter((item) => item.assignmentType !== AssignmentType.EMPTY_EHR);
        }
        if (!state.includeCaseStudy) {
          filteredAssignments = filteredAssignments.filter((item) => item.assignmentType !== AssignmentType.CASE_STUDY);
        }
        if (!state.includePastDue) {
          filteredAssignments = filteredAssignments.filter((item) => item.status !== AssignmentStatus.PAST_DUE);
        }
        state.filteredAssignments = filteredAssignments;
      })
    );
  };

  handleLaunchClick = () => {
    if (this.state.selectedAction === LinkingAction.INSTRUCTOR_AUTHOR_LANDING_PAGE) {
      window.location.href = `${window.location.origin}/#/author`;
      return;
    }

    this.setFullAssignment(this.state.selectedAssignmentId);

    const actionPayload = {
      action: this.state.selectedAction,
      assignmentId: this.state.selectedAssignmentId,
      courseId: `${this.props.courseId}`
    };

    // eslint-disable-next-line consistent-return
    return appService
      .getRedirectURL(actionPayload)
      .then((res) => res.data.redirectURL)
      .then((url) => {
        const isLocalhost = appHelper.isLocalhost();
        if (isLocalhost) {
          const parsedObject = queryString.parseUrl(url);
          window.location.href = url.replace(parsedObject.url, `${window.location.origin}/`);
        } else {
          window.location.href = url;
        }
      });
  };

  render() {
    const { isLoading, actionDropDownItems, filteredAssignments, selectedAction, selectedAssignmentId } = this.state;
    const { includeBlankChart, includeCaseStudy, includePastDue } = this.state;
    const disabledLaunchActions = [LinkingAction.INSTRUCTOR_GRADE_ASSIGNMENT_LANDING, LinkingAction.INSTRUCTOR_VIEW_ASSIGNMENT, LinkingAction.INSTRUCTOR_EDIT_ASSIGNMENT];

    const viewProps: LaunchViewProps = {
      isLoading,
      actionDropDownItems,
      assignments: filteredAssignments,
      includeBlankChart,
      includeCaseStudy,
      includePastDue,
      onAssignmentFilterChange: this.handleAssignmentFilterChange,
      onActionSelect: this.handleActionSelect,
      onAssignmentRowSelect: this.handleAssignmentRowSelect,
      onLaunchClick: this.handleLaunchClick,
      selectedAction,
      disableLaunch: disabledLaunchActions.includes(selectedAction) && !selectedAssignmentId
    };
    return <LaunchView {...viewProps} />;
  }
}

const mapStateToProps = (state) => ({
  isAuthor: appSelectors.getIsAuthor(state),
  courseId: appSelectors.getCourseId(state),
  userRole: appSelectors.getUserRole(state),
  loggedInUserId: appSelectors.getUserId(state)
});

const mapDispatchToProps = (dispatch) => ({
  setAssignment: (assignment) => dispatch(studentActions.setAssignment(assignment)),
  setAssignmentType: (assignmentType) => dispatch(studentActions.setAssignmentType(assignmentType)),
  setLocale: (locale: string) => dispatch(appActions.setLocale(locale)),
  setFullAssignment: (assignment: SimChartAssignment) => dispatch(studentActions.setFullAssignment(assignment)),
  setIsInProgressGradingEnabledFlag: (flagValue: boolean) => dispatch(appActions.setIsInProgressGradingEnabledFlag(flagValue)),
  setIsAssignmentFromSubmission: (payload) => dispatch(appActions.setIsAssignmentFromSubmission(payload))
});

export { Launch as BaseLaunch };
export default connect(mapStateToProps, mapDispatchToProps)(Launch);
