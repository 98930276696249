import { range } from 'lodash';
import { FallRiskLevel } from 'models/enum';
import { ScaleRollUpContentItem } from 'models/ui';
import { ScaleRollUpLabel } from 'components/features/chart/admission-history/fall-risk-assessment/constants';

export const RoutePath = {
  home: '/',
  backdoor: '/admin',
  launch: '/launch',
  redirect: '/redirect',
  student: {
    home: '/student',
    launchAssignmentEntryPoint: '/student/assignment/:assignmentId/start',
    blankChartingEntryPoint: '/clinical/:assignmentId/start',
    caseStudyEntryPoint: '/case-study/:assignmentId/start',
    clinicalEntryPoint: '/clinical/:assignmentId/navigate-charting',
    clinicalSetup: '/clinical/:assessmentId/clinical-setup',
    assignmentSubmission: '/student/assessment/:assessmentId/submission',
    caseStudyLanding: '/case-study/:assessmentId/home',
    vitalSigns: {
      vitalSigns: '/clinical/:assessmentId/vital-signs/vital-signs',
      intakeOutput: '/clinical/:assessmentId/vital-signs/intake-output',
      fluidBalanceChart: '/clinical/:assessmentId/vital-signs/fluid-balance-chart',
      heightWeight: '/clinical/:assessmentId/vital-signs/height-weight',
      bloodGlucose: '/clinical/:assessmentId/vital-signs/blood-glucose',
      laborProgress: '/clinical/:assessmentId/vital-signs/labor-progress'
    },
    preClinicalManager: {
      diagnosis: '/clinical/:assessmentId/pre-clinical-manager/diagnosis',
      medications: '/clinical/:assessmentId/pre-clinical-manager/medications',
      laboratoryTests: '/clinical/:assessmentId/pre-clinical-manager/laboratory-tests',
      diagnosticTests: '/clinical/:assessmentId/pre-clinical-manager/diagnostic-tests',
      report: '/clinical/:assessmentId/pre-clinical-manager/report'
    },
    providerChart: {
      providerOrder: '/clinical/:assessmentId/provider-chart/provider-order',
      historyAndPhysical: {
        landing: '/clinical/:assessmentId/provider-chart/history-physical',
        medicalHistory: '/clinical/:assessmentId/provider-chart/history-physical/medical-history',
        obstetricHistory: '/clinical/:assessmentId/provider-chart/history-physical/obstetric-history',
        physicalExamination: '/clinical/:assessmentId/provider-chart/history-physical/physical-examination',
        impression: '/clinical/:assessmentId/provider-chart/history-physical/impression',
        activitiesDailyLiving: '/clinical/:assessmentId/provider-chart/history-physical/activities-daily-living',
        reviewOfSystems: '/clinical/:assessmentId/provider-chart/history-physical/review-of-systems',
        psychiatricHistory: '/clinical/:assessmentId/provider-chart/history-physical/psychiatric-history',
        mentalStatus: '/clinical/:assessmentId/provider-chart/history-physical/mental-status'
      },
      progressNote: '/clinical/:assessmentId/provider-chart/progress-note'
    },
    admissionHistory: {
      landing: '/clinical/:assessmentId/patient-charting/admission-history',
      admissionData: '/clinical/:assessmentId/patient-charting/admission-history/admission-data',
      deliveryRoomSummary: '/clinical/:assessmentId/patient-charting/admission-history/delivery-room-summary',
      healthHistory: '/clinical/:assessmentId/patient-charting/admission-history/health-history',
      childRiskAssessment: '/clinical/:assessmentId/patient-charting/admission-history/child-risk-assessment',
      adolescentRiskAssessment: '/clinical/:assessmentId/patient-charting/admission-history/adolescent-risk-assessment',
      drugScreen: '/clinical/:assessmentId/patient-charting/admission-history/drug-screen',
      smokingScreen: '/clinical/:assessmentId/patient-charting/admission-history/smoking-screen',
      alcoholScreen: '/clinical/:assessmentId/patient-charting/admission-history/alcohol-screen',
      nutritionScreen: '/clinical/:assessmentId/patient-charting/admission-history/nutrition-screen',
      planningHospitalStay: '/clinical/:assessmentId/patient-charting/admission-history/planning-hospital-stay',
      immunizations: '/clinical/:assessmentId/patient-charting/admission-history/immunizations',
      allergy: '/clinical/:assessmentId/patient-charting/admission-history/allergy',
      homeMedication: '/clinical/:assessmentId/patient-charting/admission-history/home-medication',
      mobilityPhysicalTherapy: '/clinical/:assessmentId/patient-charting/admission-history/mobility-physical-therapy',
      activityOccupationalTherapy: '/clinical/:assessmentId/patient-charting/admission-history/activity-occupational-therapy',
      roleRelationship: '/clinical/:assessmentId/patient-charting/admission-history/role-relationship',
      developmentalScreen: '/clinical/:assessmentId/patient-charting/admission-history/developmental-screen',
      infantToddlerAssessment: '/clinical/:assessmentId/patient-charting/admission-history/infant-toddler-assessment',
      psychosocialScreen: '/clinical/:assessmentId/patient-charting/admission-history/psychosocial-screen',
      fallRiskAssessment: '/clinical/:assessmentId/patient-charting/admission-history/fall-risk-assessment'
    },
    systemAssessment: {
      savedSystemAssessments: '/clinical/:assessmentId/patient-charting/system-assessment/saved-system-assessment',
      symptomAnalysis: '/clinical/:assessmentId/patient-charting/system-assessment/symptom-analysis',
      newbornAssessment: '/clinical/:assessmentId/patient-charting/system-assessment/newborn-assessment',
      obstetricAssessment: '/clinical/:assessmentId/patient-charting/system-assessment/obstetric-assessment',
      postpartumAssessment: '/clinical/:assessmentId/patient-charting/system-assessment/postpartum-assessment',
      cardiovascularAssessment: '/clinical/:assessmentId/patient-charting/system-assessment/cardiovascular-assessment',
      respiratoryAssessment: '/clinical/:assessmentId/patient-charting/system-assessment/respiratory-assessment',
      neurologicalAssessment: '/clinical/:assessmentId/patient-charting/system-assessment/neurological-assessment',
      integumentaryAssessment: '/clinical/:assessmentId/patient-charting/system-assessment/integumentary-assessment',
      sensoryAssessment: '/clinical/:assessmentId/patient-charting/system-assessment/sensory-assessment',
      musculoskeletalAssessment: '/clinical/:assessmentId/patient-charting/system-assessment/musculoskeletal-assessment',
      gastrointestinalAssessment: '/clinical/:assessmentId/patient-charting/system-assessment/gastrointestinal-assessment',
      genitourinaryAssessment: '/clinical/:assessmentId/patient-charting/system-assessment/genitourinary-assessment',
      painAssessment: '/clinical/:assessmentId/patient-charting/system-assessment/pain-assessment',
      psychosocialAssessment: '/clinical/:assessmentId/patient-charting/system-assessment/psychosocial-assessment',
      safetyAssessment: '/clinical/:assessmentId/patient-charting/system-assessment/safety-assessment',
      specialPrecautionsIsolationAssessment: '/clinical/:assessmentId/patient-charting/system-assessment/special-precautions-isolation-assessment',
      physicalAssessment: '/clinical/:assessmentId/patient-charting/system-assessment/complete-physical-assessment',
      skinAssessment: '/clinical/:assessmentId/patient-charting/system-assessment/skin-assessment',
      neurovascularAssessment: '/clinical/:assessmentId/patient-charting/system-assessment/neurovascular-assessment'
    },
    systemNursingInterventions: {
      savedSystemNursingInterventions: '/clinical/:assessmentId/patient-charting/system-nursing-intervention/saved-system-nursing-interventions',
      laborInterventions: '/clinical/:assessmentId/patient-charting/system-nursing-intervention/labor-intervention',
      postpartumInterventions: '/clinical/:assessmentId/patient-charting/system-nursing-intervention/postpartum-intervention',
      neurologicalInterventions: '/clinical/:assessmentId/patient-charting/system-nursing-intervention/neurological-intervention',
      cardiovascularPeripheralVascularInterventions: '/clinical/:assessmentId/patient-charting/system-nursing-intervention/cardiovascular-interventions',
      respiratoryInterventions: '/clinical/:assessmentId/patient-charting/system-nursing-intervention/respiratory-intervention',
      musculoskeletalInterventions: '/clinical/:assessmentId/patient-charting/system-nursing-intervention/musculoskeletal-intervention',
      gastrointestinalInterventions: '/clinical/:assessmentId/patient-charting/system-nursing-intervention/gastrointestinal-intervention',
      genitourinaryInterventions: '/clinical/:assessmentId/patient-charting/system-nursing-intervention/genitourinary-intervention',
      integumenatryInterventions: '/clinical/:assessmentId/patient-charting/system-nursing-intervention/integumentary-intervention',
      painInterventions: '/clinical/:assessmentId/patient-charting/system-nursing-intervention/pain-intervention',
      psychosocialInterventions: '/clinical/:assessmentId/patient-charting/system-nursing-intervention/psychosocial-intervention',
      safetyInterventions: '/clinical/:assessmentId/patient-charting/system-nursing-intervention/safety-intervention'
    },
    basicNursingCare: {
      savedBasicNursingCare: '/clinical/:assessmentId/patient-charting/basic-nursing-care/saved-basic-nursing-care',
      safety: '/clinical/:assessmentId/patient-charting/basic-nursing-care/safety',
      activity: '/clinical/:assessmentId/patient-charting/basic-nursing-care/activity',
      hygieneDressingsComfort: '/clinical/:assessmentId/patient-charting/basic-nursing-care/hygiene-dressings-comfort',
      nutritionHydration: '/clinical/:assessmentId/patient-charting/basic-nursing-care/nutrition-hydration',
      elimination: '/clinical/:assessmentId/patient-charting/basic-nursing-care/elimination',
      skinCare: '/clinical/:assessmentId/patient-charting/basic-nursing-care/skin-care'
    },
    specialCharts: {
      savedSpecialCharts: '/clinical/:assessmentId/patient-charting/special-charts/saved-special-charts',
      miscellaneousNursingNotes: '/clinical/:assessmentId/patient-charting/special-charts/miscellaneous-nursing-notes',
      sbarReport: '/clinical/:assessmentId/patient-charting/special-charts/sbar-report',
      aims: '/clinical/:assessmentId/patient-charting/special-charts/aims',
      ballardMaturationalAssessment: '/clinical/:assessmentId/patient-charting/special-charts/ballard-maturational-assessment',
      bradenScale: '/clinical/:assessmentId/patient-charting/special-charts/braden-scale',
      ciwaAr: '/clinical/:assessmentId/patient-charting/special-charts/ciwa-ar',
      dailyPsychosocialAssessment: '/clinical/:assessmentId/patient-charting/special-charts/daily-psychosocial-assessment',
      deliveryRoomRecord: '/clinical/:assessmentId/patient-charting/special-charts/delivery-room-record',
      edinburghPostnatalDepressionScale: '/clinical/:assessmentId/patient-charting/special-charts/edinburgh-postnatal-depression-scale',
      flaccScale: '/clinical/:assessmentId/patient-charting/special-charts/flacc-scale',
      functionalDementiaScale: '/clinical/:assessmentId/patient-charting/special-charts/functional-dementia-scale',
      geriatricDepressionScale: '/clinical/:assessmentId/patient-charting/special-charts/geriatric-depression-scale',
      glasgowComaScale: '/clinical/:assessmentId/patient-charting/special-charts/glasgow-coma-scale',
      hamiltonAnxietyRatingScale: '/clinical/:assessmentId/patient-charting/special-charts/hamilton-anxiety-rating-scale',
      humptyDumptyFallsScale: '/clinical/:assessmentId/patient-charting/special-charts/humpty-dumpty-falls-scale',
      maniaQuestionnaire: '/clinical/:assessmentId/patient-charting/special-charts/mania-questionnaire',
      moodDisorderQuestionnaire: '/clinical/:assessmentId/patient-charting/special-charts/mood-disorder-questionnaire',
      modifiedBradenQScale: '/clinical/:assessmentId/patient-charting/special-charts/modified-braden-q-scale',
      morseFallScale: '/clinical/:assessmentId/patient-charting/special-charts/morse-fall-scale',
      neurologicalChecks: '/clinical/:assessmentId/patient-charting/special-charts/neurological-checks',
      obstructiveSleepApnea: '/clinical/:assessmentId/patient-charting/special-charts/obstructive-sleep-apnea',
      overtAggressionScale: '/clinical/:assessmentId/patient-charting/special-charts/overt-aggression-scale',
      pcaFlowsheet: '/clinical/:assessmentId/patient-charting/special-charts/pca-flowsheet',
      pediatricGlasgowComaScale: '/clinical/:assessmentId/patient-charting/special-charts/pediatric-glasgow-coma-scale',
      restraints: '/clinical/:assessmentId/patient-charting/special-charts/restraints',
      slumsExamination: '/clinical/:assessmentId/patient-charting/special-charts/slums-examination',
      seizureActivityDocumentation: '/clinical/:assessmentId/patient-charting/special-charts/seizure-activity-documentation',
      sleepDisorderScreening: '/clinical/:assessmentId/patient-charting/special-charts/sleep-disorder-screening',
      newbornAssessment: '/clinical/:assessmentId/patient-charting/special-charts/newborn-assessment'
    },
    wounds: {
      savedWounds: '/clinical/:assessmentId/patient-charting/wounds/saved-wounds',
      wounds: '/clinical/:assessmentId/patient-charting/wounds/wounds'
    },
    patientTeaching: {
      savedPatientTeaching: '/clinical/:assessmentId/patient-teaching/saved-patient-teaching',
      patientTeaching: '/clinical/:assessmentId/patient-teaching/patient-teaching'
    },
    patientData: {
      allergies: '/clinical/:assessmentId/patient-data/allergies',
      medicationReconciliation: '/clinical/:assessmentId/patient-data/medication-reconciliation',
      homeMedication: '/clinical/:assessmentId/patient-data/home-medication'
    },
    ivs: {
      savedIVs: '/clinical/:assessmentId/patient-charting/ivs/saved-ivs',
      ivs: '/clinical/:assessmentId/patient-charting/ivs/ivs'
    },
    drainsTubes: {
      savedDrainsTubes: '/clinical/:assessmentId/patient-charting/drains-tubes/saved-drains-tubes',
      drainsTubes: '/clinical/:assessmentId/patient-charting/drains-tubes/drains-tubes'
    },
    ostomies: {
      savedOstomies: '/clinical/:assessmentId/patient-charting/ostomies/saved-ostomies',
      ostomies: '/clinical/:assessmentId/patient-charting/ostomies/ostomies'
    },
    injections: {
      savedInjections: '/clinical/:assessmentId/patient-charting/injections/saved-injections',
      injections: '/clinical/:assessmentId/patient-charting/injections/injections'
    },
    summary: '/clinical/:assessmentId/summary',
    orders: {
      pharmacy: '/clinical/:assessmentId/orders/pharmacy',
      laboratory: '/clinical/:assessmentId/orders/laboratory',
      diagnosticTests: '/clinical/:assessmentId/orders/diagnostic-tests',
      therapies: '/clinical/:assessmentId/orders/therapies',
      generalOrders: '/clinical/:assessmentId/orders/general-orders',
      nutrition: '/clinical/:assessmentId/orders/nutrition'
    },
    carePlan: {
      carePlan: '/clinical/:assessmentId/care-plan/care-plan'
    },
    mar: '/clinical/:assessmentId/mar',
    patientCard: '/clinical/:assessmentId/patient-card/patient-card'
  },
  instructor: {
    home: '/instructor',
    author: '/author',
    contentSelection: '/instructor/content-selection',
    configureAssignment: '/instructor/configure-assignment',
    grade: '/instructor/grade/:assignmentId',
    studentAssessment: '/instructor/assignment/:assignmentId/grade/overview',
    blankCharting: '/instructor/clinical/:assignmentId/overview',
    caseStudyDemo: '/instructor/case-study/:assignmentId/demo',
    editAssignment: '/instructor/assignment/:assignmentId/edit'
  },
  authoring: {
    caseStudyMetadata: '/authoring/case-study-metadata',
    metadataTool: '/authoring/metadata-tool'
  }
};

export const RouteParams = {
  ASSESSMENT_ID: ':assessmentId',
  ASSIGNMENT_ID: ':assignmentId'
};

export const AppConstant = {
  TOKEN_KEY: 'security.elsevier.jwt',
  COOKIE_TOKEN_NAME: 'x-token',
  COOKIE_LINK_ID: 'x-link-id',
  COOKIE_IS_AUTHOR: 'isAuthor',
  COOKIE_IS_DEMO: 'simsng-isDemo',
  PERSIST_KEY: 'globalSimsNGState',
  STATE_VERSION_KEY: 'simsNGStateVersion',
  CACHE_KEY: 'SimsNGCache',
  RELOAD: 'reload',
  MOUSE_FLOW_CDN: '//cdn.mouseflow.com/projects/9f94af63-8fb0-451a-8246-e604fa7d61dc.js',
  RESET_ACTION: 'SIMS/RESET_STORE',
  reduxResources: {
    ROUTER: 'router',
    APP_STATE: 'appState',
    STUDENT_STATE: 'studentState',
    INSTRUCTOR_STATE: 'instructorState'
  },
  IS_DEMO_EHR_NAVIGATED: 'isDemoEhrNavigated',
  PHASE_ID: 'phaseId'
};

export const Validation = {
  ERROR_DELAY_TIME: 200
};

export const ChartValue = {
  VALUE_NA: 'n/a',
  VALUE_OTHER: 'Other',
  VALUE_SELECT_ALL: 'Select all',
  VALUE_NKA: 'NKA',
  VALUE_NONE: 'None'
};

export const ChartAmountUnit = {
  MM_HG: 'mmHg',
  UNIT_ML: 'mL',
  UNIT_ML_PER_HOUR: 'mL/hour',
  UNIT_PERCENT: '%'
};

export const VitalSign = {
  VitalSign: {
    Oxygenation: {
      ROOM_AIR: 'roomAir',
      OXYGEN_LMIN: 'oxygenMin',
      OXYGEN_PERCENT: 'oxygenPercent'
    }
  }
};

export const Event = {
  AppError: {
    API: 'app-api-error'
  },
  Breadcrumbs: {
    CATEGORY_ITEM_CLICK: 'category-item-click'
  },
  Navigation: {
    TOGGLE_OVERLAY: 'toggle-overlay',
    OVERLAY_CLICK: 'overlay-click'
  },
  BusyAction: {
    ADD: 'busy-action-added',
    REMOVE: 'busy-action-removed'
  }
};

export const ISO_FORMAT = 'YYYY-MM-DDTHH:mm:ssZ';
export const FULL_ISO_FORMAT = 'YYYY-MM-DDTHH:mm:ss.sssZ';
export const DATE_FORMAT = 'MM-DD-YYYY';
export const DATE_FORMAT_ANZ = 'DD/MM/YYYY';
export const TIME_FORMAT = 'HH:mm';
export const FULL_TIME_FORMAT = 'HH:mm:ss';
export const DATE_TIME_FORMAT = 'MM-DD-YYYY HH:mm';
export const DATE_TIME_AM_PM_FORMAT = 'MM-DD-YYYY hh:mm a';
export const DATE_TIME_AM_PM_FORMAT_ANZ = 'DD/MM/YYYY hh:mm a';
export const GRAPH_DATE_TIME_FORMAT = 'MM-DD-YYYY [at] HH:mm';
export const GRAPH_DATE_TIME_FORMAT_ANZ = 'DD/MM/YYYY [at] HH:mm';
export const MOMENT_UNIT_DAYS = 'days';
export const MOMENT_UNIT_MINUTES = 'minutes';
export const MOMENT_INCLUSIVITY_ALL = '[]';

export const REDIRECT_DELAY_TIME = 1500;
export const RESULTS_PER_PAGE = 25;

export const SECONDS_OF_THE_DAY = 86400;

export const SECONDS_OF_THE_HOUR = 3600;

export const SECONDS_OF_THE_MINUTE = 60;

export const PEDIATRIC_MAX_AGE_IN_DAYS = 17 * 360;

export const PEDIATRIC_AGE_UNDER_16 = 16 * 360;

export const PEDIATRIC_AGE_UNDER_9 = 9 * 360;

export const PEDIATRIC_AGE_UP_TO_1 = 360;

export const WOMAN_HEALTH_MIN_AGE_IN_DAYS = 12 * 360;

export const DAY_IN_YEAR = 365;

export const NAV_ID = {
  TIME: 'TIME',
  PATIENT: 'CLINSETUP_000',
  SUMMARY: 'SUMMARY_000',
  PATIENT_CHARTING: 'PATIENT_CHARTING_001',
  VITALSIGNS: 'VITALSIGNS_002',
  INTAKE_OUTPUT: 'VITALSIGNS_003',
  HEIGHT_WEIGHT: 'VITALSIGNS_004',
  BLOOD_GLUCOSE: 'VITALSIGNS_005',
  LABOR_PROGRESS: 'VITALSIGNS_006',
  DIAGNOSIS: 'PRE_CLINICAL_MANAGER_002',
  MEDICATIONS: 'PRE_CLINICAL_MANAGER_003',
  LABORATORY_TESTS: 'PRE_CLINICAL_MANAGER_004',
  DIAGNOSTIC_TESTS: 'PRE_CLINICAL_MANAGER_005',
  SUBMISSION_OVERVIEW: 'SUBMISSION_OVERVIEW_001',
  PROVIDER_CHART: 'PROVIDER_001',
  HISTORY_PHYSICAL: 'PROVIDER_003',
  MEDICAL_HISTORY: 'PROVIDER_004',
  OBSTETRIC_HISTORY: 'PROVIDER_009',
  PHYSICAL_EXAMINATION: 'PROVIDER_005',
  IMPRESSIONS: 'PROVIDER_006',
  ACTIVITIES_DAILY_LIVING: 'PROVIDER_007',
  REVIEW_OF_SYSTEMS: 'PROVIDER_010',
  PSYCHIATRIC_HISTORY: 'PROVIDER_011',
  MENTAL_STATUS: 'PROVIDER_012',
  SYMPTOM_ANALYSIS: 'SYSTEM_ASSESSMENT_002',
  NEWBORN_ASSESSMENT: 'SYSTEM_ASSESSMENT_003',
  OBSTETRIC_ASSESSMENT: 'SYSTEM_ASSESSMENT_004',
  POSTPARTUM_ASSESSMENT: 'SYSTEM_ASSESSMENT_005',
  CARDIOVASCULAR_ASSESSMENT: 'SYSTEM_ASSESSMENT_006',
  RESPIRATORY_ASSESSMENT: 'SYSTEM_ASSESSMENT_007',
  NEUROLOGICAL_ASSESSMENT: 'SYSTEM_ASSESSMENT_008',
  INTEGUMENTARY_ASSESSMENT: 'SYSTEM_ASSESSMENT_009',
  SENSORY_ASSESSMENT: 'SYSTEM_ASSESSMENT_010',
  MUSCULOSKELETAL_ASSESSMENT: 'SYSTEM_ASSESSMENT_011',
  GASTROINTESTINAL_ASSESSMENT: 'SYSTEM_ASSESSMENT_012',
  GENITOURINARY_ASSESSMENT: 'SYSTEM_ASSESSMENT_013',
  PAIN_ASSESSMENT: 'SYSTEM_ASSESSMENT_014',
  PSYCHOSOCIAL_ASSESSMENT: 'SYSTEM_ASSESSMENT_015',
  SAFETY_ASSESSMENT: 'SYSTEM_ASSESSMENT_016',
  SPECIAL_PRECAUTION_ISOLATION_ASSESSMENT: 'SYSTEM_ASSESSMENT_017',
  COMPLETE_PHYSICAL_ASSESSMENT: 'SYSTEM_ASSESSMENT_018',
  SAVED_SYSTEM_ASSESSMENT: 'SYSTEM_ASSESSMENT_019',
  ADMISSION_HISTORY: 'ADMISSION_HISTORY_001',
  ADMISSION_DATA: 'ADMISSION_HISTORY_002',
  HEALTH_HISTORY: 'ADMISSION_HISTORY_003',
  DRUG_SCREEN: 'ADMISSION_HISTORY_004',
  SMOKING_SCREEN: 'ADMISSION_HISTORY_005',
  ALCOHOL_SCREEN: 'ADMISSION_HISTORY_006',
  NUTRITION_SCREEN: 'ADMISSION_HISTORY_007',
  PLANNING_HOSPITAL_STAY: 'ADMISSION_HISTORY_008',
  IMMUNIZATIONS: 'ADMISSION_HISTORY_009',
  ALLERGY_INFORMATION: 'ADMISSION_HISTORY_010',
  HOME_MEDICATION: 'ADMISSION_HISTORY_011',
  MOBILITY_PHYSICAL_THERAPY: 'ADMISSION_HISTORY_012',
  ACTIVITY_OCCUPATIONAL_THERAPY: 'ADMISSION_HISTORY_013',
  ROLE_RELATIONSHIP: 'ADMISSION_HISTORY_014',
  CHILD_RISK_ASSESSMENT: 'ADMISSION_HISTORY_016',
  DELIVERY_ROOM_SUMMARY: 'ADMISSION_HISTORY_015',
  INFANT_TODDLER_ASSESSMENT: 'ADMISSION_HISTORY_017',
  ADOLESCENT_RISK_ASSESSMENT: 'ADMISSION_HISTORY_018',
  DEVELOPMENTAL_SCREEN: 'ADMISSION_HISTORY_019',
  PSYCHOSOCIAL_SCREEN: 'ADMISSION_HISTORY_020',
  FALL_RISK_ASSESSMENT: 'ADMISSION_HISTORY_021',
  NEUROLOGICAL_INTERVENTIONS: 'SYSTEM_NURSING_002',
  CARDIOVASCULAR: 'SYSTEM_NURSING_003',
  RESPIRATORY_INTERVENTIONS: 'SYSTEM_NURSING_004',
  MUSCULOSKELETAL_INTERVENTIONS: 'SYSTEM_NURSING_005',
  GASTROINTESTINAL_INTERVENTIONS: 'SYSTEM_NURSING_006',
  GENITOURINARY_INTERVENTIONS: 'SYSTEM_NURSING_007',
  INTEGUMENTARY_INTERVENTIONS: 'SYSTEM_NURSING_008',
  SAVED_SYSTEM_NURSING_INTERVENTIONS: 'SYSTEM_NURSING_012',
  PAIN_INTERVENTIONS: 'SYSTEM_NURSING_009',
  LABOR_INTERVENTIONS: 'SYSTEM_NURSING_013',
  POSTPARTUM_INTERVENTIONS: 'SYSTEM_NURSING_014',
  SAVED_SPECIAL_CHART: 'SPECIAL_CHARTS_031',
  SAVED_WOUNDS: 'WOUNDS_002',
  WOUNDS: 'WOUNDS_003',
  SAVED_INTRAVENOUS: 'INTRAVENOUS_002',
  INTRAVENOUS: 'INTRAVENOUS_003',
  SAVED_OSTOMIES: 'OSTOMIES_002',
  OSTOMIES: 'OSTOMIES_003',
  SAVED_INJECTIONS: 'INJECTIONS_002',
  INJECTIONS: 'INJECTIONS_003',
  SAVED_BASIC_NURSING_CARE: 'BASIC_NURSING_002',
  SAFETY: 'BASIC_NURSING_003',
  ACTIVITY: 'BASIC_NURSING_004',
  HYGIENE_DRESSINGS_COMFORT: 'BASIC_NURSING_005',
  NUTRITION_HYDRATION: 'BASIC_NURSING_006',
  ELIMINATION: 'BASIC_NURSING_007',
  SKIN_CARE: 'BASIC_NURSING_008',
  SBAR_REPORT: 'SPECIAL_CHARTS_003',
  BALLARD_MATURATIONAL_ASSESSMENT: 'SPECIAL_CHARTS_005',
  BRADEN_SCALE: 'SPECIAL_CHARTS_006',
  CIWA_AR: 'SPECIAL_CHARTS_007',
  FLACC_SCALE: 'SPECIAL_CHARTS_011',
  HUMPTY_DUMPTY_FALLS_SCALE: 'SPECIAL_CHARTS_016',
  MORSE_FALL_SCALE: 'SPECIAL_CHARTS_020',
  PCA_FLOWSHEET: 'SPECIAL_CHARTS_025',
  RESTRAINTS: 'SPECIAL_CHARTS_027',
  NEWBORN_ASSESSMENT_SPECIAL: 'SPECIAL_CHARTS_032',
  EDINBURGH_POSTNATAL_DEPRESSION: 'SPECIAL_CHARTS_010',
  SAVED_PATIENT_TEACHING: 'PATIENT_TEACHING_002',
  PATIENT_TEACHING: 'PATIENT_TEACHING_003',
  SAVED_DRAINS_TUBES: 'DRAINS_TUBES_002',
  DRAINS_TUBES: 'DRAINS_TUBES_003',
  ORDER_ENTRY: 'ORDER_ENTRY_001',
  PHARMACY: 'ORDER_ENTRY_002',
  LABORATORY: 'ORDER_ENTRY_003',
  DIAGNOSTIC_TESTS_ORDER_ENTRY: 'ORDER_ENTRY_004',
  THERAPIES: 'ORDER_ENTRY_005',
  GENERAL_ORDERS: 'ORDER_ENTRY_006',
  NUTRITION: 'ORDER_ENTRY_007',
  PATIENT_DATA: 'PATIENT_DATA_001',
  ALLERGIES: 'PATIENT_DATA_002',
  MEDICATION_RECONCILIATION: 'PATIENT_DATA_003',
  PATIENT_DATA_HOME_MEDICATION: 'PATIENT_DATA_004',
  PATIENT_CARD: 'PATIENT_CARD_002',
  MAR: 'MAR_001',
  CARE_PLAN: 'CARE_PLAN_002'
};

// Last element that is focusable
// Number coordinated with its position from the bottom of the NodeList
export const LastElement = {
  ENABLED_SAVE_BUTTON: 2,
  DISABLED_SAVE_BUTTON: 4
};

export const AppMessage = {
  NO_CHART_DATA: 'Can not load chart data',
  NO_GRAPH_DATA: 'No data available in graph',
  NO_TABLE_DATA: 'No data available in table'
};

export const Analytics = {
  defaultAnalyticsConfig: {
    businessUnit: 'els:nh',
    cmsName: '',
    language: 'en',
    noTracking: 'false',
    productName: 'sing',
    accountName: 'eols:',
    accountId: 'eols:',
    userId: 'eols:',
    departmentId: '',
    departmentName: '',
    environment: ''
  }
};

export const ChartLabel = {
  MARK_ALL_THAT_APPLY: 'Mark All That Apply'
};

export enum ParentSections {
  SPECIAL_CHARTS = 'SPECIAL_CHARTS',
  SYSTEM_ASSESSMENT = 'SYSTEM_ASSESSMENT',
  WOUNDS = 'WOUNDS',
  BASIC_NURSING = 'BASIC_NURSING',
  SYSTEM_NURSING = 'SYSTEM_NURSING',
  INTRAVENOUS = 'INTRAVENOUS',
  OSTOMIES = 'OSTOMIES',
  DRAINS_TUBES = 'DRAINS_TUBES',
  PATIENT_TEACHING = 'PATIENT_TEACHING',
  INJECTIONS = 'INJECTIONS'
}

export const SavedPatientChartsRoutes = new Map<string, string>([
  [ParentSections.SPECIAL_CHARTS, RoutePath.student.specialCharts.savedSpecialCharts],
  [ParentSections.SYSTEM_ASSESSMENT, RoutePath.student.systemAssessment.savedSystemAssessments],
  [ParentSections.WOUNDS, RoutePath.student.wounds.savedWounds],
  [ParentSections.BASIC_NURSING, RoutePath.student.basicNursingCare.savedBasicNursingCare],
  [ParentSections.SYSTEM_NURSING, RoutePath.student.systemNursingInterventions.savedSystemNursingInterventions],
  [ParentSections.INTRAVENOUS, RoutePath.student.ivs.savedIVs],
  [ParentSections.OSTOMIES, RoutePath.student.ostomies.savedOstomies],
  [ParentSections.DRAINS_TUBES, RoutePath.student.drainsTubes.savedDrainsTubes],
  [ParentSections.PATIENT_TEACHING, RoutePath.student.patientTeaching.savedPatientTeaching],
  [ParentSections.INJECTIONS, RoutePath.student.injections.savedInjections]
]);

export const SectionNavIdPatterns = {
  SPECIAL_CHARTS: 'SPECIAL_CHARTS*',
  SYSTEM_ASSESSMENTS: 'SYSTEM_ASSESSMENT*',
  WOUNDS: 'WOUNDS*',
  BASIC_NURSING: 'BASIC_NURSING*',
  SYSTEM_NURSING: 'SYSTEM_NURSING*',
  INTRAVENOUS: 'INTRAVENOUS*',
  OSTOMIES: 'OSTOMIES*',
  DRAINS_TUBES: 'DRAINS_TUBES*',
  PATIENT_TEACHING: 'PATIENT_TEACHING*',
  CARE_PLAN: 'CARE_PLAN*',
  INJECTIONS: 'INJECTIONS*'
};

export const AppLinkActions = {
  ASSIGNMENT_CREATE: 'ASSIGNMENT_CREATE',
  ASSIGNMENT_EDIT: 'ASSIGNMENT_EDIT',
  ASSIGNMENT_PREVIEW: 'ASSIGNMENT_PREVIEW',
  ASSIGNMENT_PERFORMANCE_VIEW: 'ASSIGNMENT_PERFORMANCE_VIEW',
  ASSESSMENT_START: 'ASSESSMENT_START',
  ASSESSMENT_PERFORMANCE_VIEW: 'ASSESSMENT_PERFORMANCE_VIEW',
  ASSESSMENT_GRADE_EDIT: 'ASSESSMENT_GRADE_EDIT',
  PERFORMANCE_VIEW: 'PERFORMANCE_VIEW',
  AUTHORING: 'AUTHORING'
};

export const AuthoringFakeData = {
  assessmentId: '123',
  assessment: {
    assignmentId: 'assignmentId',
    simChartId: 'chartId',
    eolsAssessmentId: '123'
  }
};

export const FillInTheBlankPattern = ':_';

export const AuthoringPhaseIndexes = [1, 2, 3, 4];

export const assignmentIdType = {
  fakeAssignmentId: 'Fake Assignment Id',
  realAssignmentId: 'Real Assignment Id'
};

export const SkipLinkId = {
  navigation: {
    section: 'nav_start',
    title: 'navigation'
  },
  main: {
    section: 'children-wrapper',
    title: 'main content'
  },
  footer: {
    section: 'footer',
    title: 'footer'
  }
};

export const ExcludesSkipLinkPaths = [
  RoutePath.student.clinicalSetup,
  RoutePath.student.assignmentSubmission,
  RoutePath.student.caseStudyLanding,
  RoutePath.student.launchAssignmentEntryPoint,
  RoutePath.student.blankChartingEntryPoint,
  RoutePath.student.caseStudyEntryPoint,
  RoutePath.student.clinicalEntryPoint,
  RoutePath.instructor.contentSelection,
  RoutePath.instructor.configureAssignment,
  RoutePath.instructor.grade,
  RoutePath.instructor.studentAssessment,
  RoutePath.instructor.blankCharting,
  RoutePath.instructor.caseStudyDemo,
  RoutePath.instructor.editAssignment
];

// in author flow, keep author data when navigate from navId in key to navId in value
// currently it is used in ChartWrapper for keeping author data and displayRecord button when navigate from Pharmacy to Mar
export const multiChartAuthorDataNavMapping = {
  [NAV_ID.PHARMACY]: NAV_ID.MAR,
  [NAV_ID.HOME_MEDICATION]: NAV_ID.PATIENT_DATA_HOME_MEDICATION,
  [NAV_ID.PATIENT_DATA_HOME_MEDICATION]: NAV_ID.HOME_MEDICATION,
  [NAV_ID.HOME_MEDICATION]: NAV_ID.MEDICATION_RECONCILIATION,
  [NAV_ID.PATIENT_DATA_HOME_MEDICATION]: NAV_ID.MEDICATION_RECONCILIATION
};

export const organizedISBNS = {
  SHERPATH_BOOK_ORGANIZED: 'sherpath_book_organized',
  OBJECTIVE_ORGANIZED: 'sherpath_ia'
};

export const CaseStudyPaths = [RoutePath.student.caseStudyLanding, RoutePath.instructor.contentSelection];

export const SimChartStandalone = 'Course-Based SimChart Standalone';

export enum Locales {
  EN_US = 'en_US',
  EN_AU = 'en_AU'
}

export const DefaultLocale = Locales.EN_US;

export const TimeSpentMsg = {
  ERROR: 'can not load assignment total time',
  LESS_THAN_ONE_MIN: '< 1 min',
  LESS_THAN_ONE_MINUTE: 'Less than 1 minute'
};

export const DefaultScaleRollUpContent: (ScaleRollUpContentItem & { level: FallRiskLevel })[] = [
  {
    label: ScaleRollUpLabel.LOW_RISK,
    range: range(24),
    level: FallRiskLevel.LOW_RISK
  },
  {
    label: ScaleRollUpLabel.MEDIUM_RISK,
    range: range(25, 44),
    level: FallRiskLevel.MEDIUM_RISK
  },
  {
    label: ScaleRollUpLabel.HIGH_RISK,
    range: range(45, 200),
    level: FallRiskLevel.HIGH_RISK
  }
];

export const DateFormatByLocale: Record<Locales, string> = {
  [Locales.EN_US]: DATE_FORMAT,
  [Locales.EN_AU]: DATE_FORMAT_ANZ
};

export const DateTimeFormatByLocale: Record<Locales, string> = {
  [Locales.EN_US]: `${DATE_FORMAT} ${TIME_FORMAT}`,
  [Locales.EN_AU]: `${DATE_FORMAT_ANZ} ${TIME_FORMAT}`
};

export const DateTimeAmPmFormatByLocale: Record<Locales, string> = {
  [Locales.EN_US]: DATE_TIME_AM_PM_FORMAT,
  [Locales.EN_AU]: DATE_TIME_AM_PM_FORMAT_ANZ
};

// Centralize the param keys and values
export enum ParamKeys {
  LOCALE = 'locale'
}

export type ParamValues = {
  locale: Locales;
};

export enum FeatureName {
  SHOW_ASSIGNMENT_STUDENTS = 'SHOW_ASSIGNMENT_STUDENTS',
  SHOW_CLINICAL_UNIT = 'SHOW_CLINICAL_UNIT',
  ALLOW_COMPLETE_PAST_DUE_ASSIGNMENT = 'ALLOW_COMPLETE_PAST_DUE_ASSIGNMENT',
  SHOW_PENDO_HEADER_US = 'SHOW_PENDO_HEADER_US',
  SHOW_PENDO_HEADER_ANZ = 'SHOW_PENDO_HEADER_ANZ',
  ENABLE_IN_PROGRESS_GRADING = 'ENABLE_IN_PROGRESS_GRADING'
}
