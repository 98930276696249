import { ChartValue, Locales, NAV_ID, RoutePath } from 'constants/app.constant';
import { appHelper, cssHelper } from 'helpers';
import { Avatar } from 'components/common';
import { ELSFlex, ELSFlexItem, ELSInlineLoader } from 'components/common/els';
import LabelField from 'components/common/label-field/LabelField';
import './patient.ribbon.scss';

export interface PatientRibbonViewProps {
  patientIdentifier: string;
  dob: string;
  sex: string;
  specifyBelow: string;
  gender: string;
  pronouns: string;
  height: string;
  weight: string;
  weightDateRecorded: string;
  mrn: string;
  room: string;
  provider: string;
  clinicalUnit: string;
  isLoading: boolean;
  isShowClinicalUnit: boolean;
  allergies: string[];
  nutrition: string[];
  alerts: string[];
  codeStatus: string[];
  isolation: string[];
  onNavigate: Function;
  onNavigateGeneralOrder: Function;
  locale: Locales;
}

const formatValue = (values: string[], displayValueIfEmpty: string = ChartValue.VALUE_NONE) => {
  return values?.length > 0 ? appHelper.formatNumberToString(values.length) : displayValueIfEmpty;
};

const PatientRibbonView = (props: PatientRibbonViewProps) => {
  const allergiesValue = formatValue(props.allergies, ChartValue.VALUE_NKA);
  const nutritionValue = formatValue(props.nutrition);
  const alertsValue = formatValue(props.alerts);
  const codeStatusValue = formatValue(props.codeStatus);
  const isolationValue = formatValue(props.isolation);

  const renderPatientInfo = () => {
    if (props.locale === Locales.EN_US) {
      return (
        <LabelField
          label={props.patientIdentifier}
          labelValue={`${props.dob}, ${props.sex}`}
          color="n7"
          labelFontSize="h4"
          customClassLabel={['label-field--underline', 'u-els-font-family-bold']}
        />
      );
    }
    const genderPatient = props.gender ? `G: ${props.gender}` : '';
    const pronounsPatient = props.pronouns ? `P: ${props.pronouns}` : '';
    const labelGenderPronouns = genderPatient && pronounsPatient ? `${genderPatient}, ${pronounsPatient}` : genderPatient || pronounsPatient;

    return (
      <>
        <LabelField
          label={props.patientIdentifier}
          labelValue={`${props.dob}, S: ${props.sex === 'Anotherterm' ? props.specifyBelow : props.sex}`}
          color="n7"
          labelFontSize="h4"
          customClassLabel={['label-field--underline', 'u-els-font-family-bold']}
        />
        <LabelField labelValue={labelGenderPronouns} color="n7" labelFontSize="h4" />
      </>
    );
  };
  return (
    <div className="patient-ribbon">
      {props.isLoading && <ELSInlineLoader />}
      <div className={cssHelper.mapElsCss({ els: 'padding-2x', md: 'padding-1x1o2' })}>
        <ELSFlex left middle>
          <ELSFlexItem>
            <Avatar name={props.patientIdentifier} />
          </ELSFlexItem>
          <ELSFlexItem className={cssHelper.mapElsCss({ els: 'padding-left-2x', md: 'padding-left-1x1o2' })} column>
            <ELSFlex className="patient-ribbon__patient-info" left wrap>
              <ELSFlexItem>
                <ELSFlex left wrap className="u-flex-grid-2x">
                  <ELSFlexItem>{renderPatientInfo()}</ELSFlexItem>
                  <ELSFlexItem className="patient-ribbon__patient-info__code_status">
                    <LabelField
                      bold
                      color="n7"
                      labelType="link"
                      label="Code Status"
                      labelValue={codeStatusValue}
                      contents={props.codeStatus}
                      onNavigate={() => props.onNavigateGeneralOrder(NAV_ID.GENERAL_ORDERS, RoutePath.student.orders.generalOrders, 'Code Status')}
                    />
                  </ELSFlexItem>
                  <ELSFlexItem>
                    <LabelField
                      bold
                      color="n7"
                      labelType="link"
                      label="Alerts"
                      labelValue={alertsValue}
                      contents={props.alerts}
                      onNavigate={() => props.onNavigateGeneralOrder(NAV_ID.GENERAL_ORDERS, RoutePath.student.orders.generalOrders, 'Alerts')}
                    />
                  </ELSFlexItem>
                  <ELSFlexItem>
                    <LabelField
                      bold
                      color="n7"
                      labelType="link"
                      label="Isolation"
                      labelValue={isolationValue}
                      contents={props.isolation}
                      onNavigate={() => props.onNavigateGeneralOrder(NAV_ID.GENERAL_ORDERS, RoutePath.student.orders.generalOrders, 'Isolation')}
                    />
                  </ELSFlexItem>
                  <ELSFlexItem>
                    <LabelField
                      bold
                      color="n7"
                      labelType="link"
                      label="Allergies"
                      labelValue={allergiesValue}
                      contents={props.allergies}
                      onNavigate={() => props.onNavigate(NAV_ID.ALLERGIES, RoutePath.student.patientData.allergies)}
                    />
                  </ELSFlexItem>
                  <ELSFlexItem>
                    <LabelField
                      bold
                      color="n7"
                      labelType="link"
                      label="Diet"
                      labelValue={nutritionValue}
                      contents={props.nutrition}
                      onNavigate={() => props.onNavigate(NAV_ID.NUTRITION, RoutePath.student.orders.nutrition)}
                    />
                  </ELSFlexItem>
                  <ELSFlexItem>
                    <LabelField label="Height" labelValue={props.height} color="n7" bold />
                  </ELSFlexItem>
                  <ELSFlexItem>
                    <LabelField label={props.weightDateRecorded?.length ? `Weight (${props.weightDateRecorded})` : 'Weight'} labelValue={props.weight} color="n7" bold />
                  </ELSFlexItem>
                  <ELSFlexItem>
                    <LabelField label="MRN" labelValue={props.mrn} />
                  </ELSFlexItem>
                  <ELSFlexItem>
                    <LabelField label="Room" labelValue={props.room} />
                  </ELSFlexItem>
                  <ELSFlexItem>
                    <LabelField label="Health Care Provider" labelValue={props.provider} />
                  </ELSFlexItem>
                  {props.isShowClinicalUnit && (
                    <ELSFlexItem>
                      <LabelField label="Clinical Unit" labelValue={props.clinicalUnit} />
                    </ELSFlexItem>
                  )}
                </ELSFlex>
              </ELSFlexItem>
            </ELSFlex>
          </ELSFlexItem>
        </ELSFlex>
      </div>
    </div>
  );
};

PatientRibbonView.displayName = 'PatientRibbonView';

export default PatientRibbonView;
