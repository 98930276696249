import { Component } from 'react';
import { ChartFragment } from 'models/api-response';
import { SectionNavIdPatterns } from 'constants/app.constant';
import { dateTimeHelper } from 'helpers';
import { chartService } from 'services';
import { SavedPatientCharting } from 'components/common';
import { TableItem } from 'components/common/saved-patient-charting/SavedPatientCharting';

class SavedSpecialCharts extends Component {
  static displayName = 'SavedSpecialCharts';

  toTableData = (fragments: ChartFragment[]): TableItem[] =>
    fragments
      .map((fragment) => ({
        active: fragment.active,
        chart: fragment.chartData.fragmentTitle,
        chartSection: fragment.chartData.chartTitle,
        enteredBy: chartService.formatName(fragment.creator),
        entryTime: dateTimeHelper.toDateTime(fragment.chartingAt),
        fragmentCreatorId: fragment.creator?.id,
        fragmentType: fragment.fragmentType,
        id: fragment.fragmentId,
        linkedFragmentId: fragment.linkedFragmentId,
        navElementId: fragment.navElementId
      }));

  render() {
    const viewProps = {
      chartTitle: 'Saved Special Charts',
      navIds: [SectionNavIdPatterns.SPECIAL_CHARTS],
      toTableData: this.toTableData
    };
    return <SavedPatientCharting {...this.props} {...viewProps} />;
  }
}

export default SavedSpecialCharts;
