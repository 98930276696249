import { History } from 'history';
import { ReactElement } from 'react';
import { IntlShape } from 'react-intl';
import { AssessmentRS, ChartFragment, ChartFragmentRS, ChartFragmentsRS, Record, TimeSpentRS } from 'models/api-response';
import { Locales } from 'constants/app.constant';
import { ChartFieldContentMeta, ChartFieldMeta, ChartFieldValidationMeta } from 'components/pages/metadata-tool-home/MetadataInput';
import {
  AssessmentStatus,
  AssignmentTargetType,
  AssignmentType,
  EventType,
  FallRiskLevel,
  FormFieldDataType,
  FormFieldInputType,
  LocationType,
  PhaseProgress,
  PhaseType
} from './enum';

export interface SkinnyAssignment {
  assignmentId: number;
  title: string;
}

export enum ColumnAlign {
  Left = 'left',
  Center = 'center',
  Right = 'right'
}

export interface User {
  id: number;
  firstName: string;
  lastName?: string;
  emailAddress?: string;
}

export interface Patient {
  heightWeightRecord: {
    height: {
      centimeters: string;
      feet: string;
      inches: string;
    };
    weight: string;
    weightDateRecorded: string;
  };
  clinicalSetup: {
    clinicalFirstDay: string;
    clinicalUnit: {
      choices: {
        value: string;
        id: string;
      }[];
    };
    patientDateOfBirth: string;
    patientIdentifier: string;
    patientSex: string;
    specifyBelow: string;
    gender: string;
    pronouns: string;
    providerFirstLastInitial: string;
    mrn?: string;
    roomNumber?: string;
  };
  allergyRecords: {
    allergyType: string;
    allergen: string;
    allergenOther: string;
    medication: string;
    allergenMedicationOther: string;
  }[];
  nutritionRecords: {
    orderDescription: string;
    orderDetail: string;
  }[];
  generalOrderRecords: {
    orderDescription: string;
    orderDetails: string;
  }[];
}

export interface PregnancyRecord extends Record {
  contentId: string;
  parentLabel: string;
}

export interface PregnancyInfoRecord {
  id: string;
  active: boolean;
  complications: PregnancyRecord;
  durationOfLabor: PregnancyRecord;
  gestationalWeeks: PregnancyRecord;
  howLongAgo: PregnancyRecord;
  outcome: PregnancyRecord;
  typeOfDelivery: PregnancyRecord;
}

export interface PreviousPregnanciesSection {
  sectionTitle: string;
  records: PregnancyInfoRecord[];
}

export interface BasicHistory {
  chartTime: number;
  note?: string;
  entryBy: string;
}

export interface LungAuscultationLocation {
  active: boolean;
  location: string;
  notes?: string;
  records: Record[];
  sounds?: string;
  wheezeDescription?: string;
}

export interface PulseLocation {
  active: boolean;
  location: string;
  irregular?: string;
  murmur?: string;
  pulseAndDoppler?: string;
  records: Record[];
}

export interface ChartTable {
  title?: string;
  headers: ChartTableHeader[];
  data: (BasicHistory | LungAuscultationLocation | PulseLocation | ChartFieldMeta | ChartFieldContentMeta | ChartFieldValidationMeta)[];
  css?: string[];
  tableCss?: string;
  hideDelete?: boolean;
}

export interface ChartTableHeader {
  text?: string;
  field: string;
  align?: ColumnAlign;
  sortable?: boolean;
  customRender?: Function;
  customSort?: Function;
  section?: string;
  customHeader?: ReactElement;
}

export interface DropDownItem {
  id?: string;
  name: string;
  value: string;
}

export interface MultiSelectItem extends DropDownItem {
  id?: string;
  selected?: boolean;
  isDisabled?: boolean;
  className?: string;
}

export interface FormField {
  label: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any;
  name: string;
  onChange?: Function;
  isHidden?: boolean;
  resourceUri?: string;
}

export interface ChartMetaFormField extends FormField {
  chartContent?: ContentItem[];
  fieldIdsToClearValue?: string[];
  contentIds?: string[];
  scaleContent?: ScaleContent[];
  disabled: boolean;
  errors: ErrorField[];
  hide?: boolean;
  inputType: FormFieldInputType;
  isRequired: boolean;
  labelTooltip?: string;
  labelMultilineTooltip?: JSX.Element;
  name: string;
  onBlur?: Function;
  onChangePreValidation?(chartField: ChartMetaFormField, formFieldMap: Map<string, ChartMetaFormField>);
  onChangePostValidation?(chartField: ChartMetaFormField, formFieldMap: Map<string, ChartMetaFormField>);
  onGroupChange?(chartField: ChartMetaFormField): Array<ChartMetaFormField>;
  warning: ErrorField[];
  errorLabel?: string;
  parentLabel?: string;
  changeCallback?: Function;
  setFormFieldLabel?: Function;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  defaultValue?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  extraData?: any;
  selected?: boolean;
}

interface FormFieldContent extends FormField {
  collapsedLabel?: string;
  dataType?: FormFieldDataType;
  formFieldId?: string;
  expandedLabel?: string;
  id: string;
  fieldLabel?: string;
}

export interface ChartFieldContent extends FormFieldContent {
  dataPoint: boolean;
  dataType: FormFieldDataType;
  formFieldId: string;
  order: number;
  questionChartFieldIds?: string[];
  questionFormFieldIds?: string[];
  validationSetIds: string[];
  rollUpTitle?: string;
  externalReference?: string;
  externalReferences?: ExternalReference[];
  active: boolean;
  nestedFieldId: string;
  isShown?: boolean;
}

export interface ExternalReference {
  label: string;
  unit?: string;
  range?: string;
}

export interface ContentItem extends FormFieldContent {
  disabled?: boolean;
  displayOrder: number;
  options?: ContentItem[];
  questionContexts?: string[];
  onChange: Function;
  score?: { label: string; value: string };
  selected: boolean;
  validationSetIds?: string[];
  isActive: boolean;
  isHidden?: boolean;
  nestedFieldId?: string;
  referenceFields?: ExternalReference[];
  resourceUri?: string;
}

export interface NavigationItem {
  children?: NavigationItem[];
  displayOrder?: number;
  id?: string;
  landing?: boolean;
  leaf?: boolean;
  level?: number;
  path: string;
  shown: boolean;
  text: string;
  parentText?: string;
  fullPath?: string;
  isTopChart?: boolean;
}

export interface ErrorField {
  id?: string;
  field: string;
  message?: string;
  groupId?: string;
  priority: number;
  type?: string;
}

// TODO - deprecate this
export interface ErrorGroup {
  groupId: string;
  displayName: string;
  priority: number;
  formFields?: string[];
}

export interface ValidationRule {
  chartFieldSelectionValue?: string;
  formFieldId: string;
  id: string;
  maxRange?: number;
  message?: string;
  minRange?: number;
  parentValidationId?: string;
  regex?: string;
  validationActionType: string;
  validationType: string;
  targets?: {
    formFieldId: string;
    chartFieldContentId?: string;
    value?: string;
  }[];
  getValueToCompare?: Function;
}

export interface ChartMetadata {
  chartFieldContentSet: ChartFieldContent[];
  chartFieldValidationSet: ValidationRule[];
}

export interface ChartMetadataState {
  initState(data: Map<string, ChartMetaFormField>, initStateCallback?: Function, extraValidationRules?: ValidationRule[]): Function;
  getContentMap(): Map<string, string>;
  resetSubsetOfFormFields(fields: ChartMetaFormField[]): Function;
  hasChangesInTargetFields(fields: ChartMetaFormField[]): boolean;
}

export interface SaveOptions {
  navId?: string;
  formFieldMap?: Map<string, ChartMetaFormField>;
  defaultFormFields?: Map<string, ChartMetaFormField>;
  beforeSave?: Function;
  afterSave?: Function;
  hideSaveMessage?: boolean;
}

export interface CancelOptions {
  selectorToScroll?: string;
}

export interface ChartSharedProps {
  pushUnsavedChangesList?: Function;
}

export interface SideBarCommonProps {
  setIsChartSidebarOpen?: Function;
  handleCloseAttempt?: Function;
}

export interface HandleCloseSidebarFuncProps {
  sidebarProps: Function;
  onCloseClick: Function;
}

export interface ChartComponentProps extends ChartMetadataState {
  history?: History;
  selectedNavId: string;
  student: User;
  assessment: AssessmentRS;
  isAssessmentOwner: boolean;
  isAssessmentSubmitted?: boolean;
  isAuthor?: boolean;
  isEmbeddedChart?: boolean;
  chartingTime?: string;
  chartMetadata: ChartMetadata;
  formFieldMap: Map<string, ChartMetaFormField>;
  hasUnsavedChanges: boolean;
  isDirty?: boolean;
  formSubmittedCount: number;
  enableDisplayRecordsButton?: boolean;
  fetchChartMetadata: Function;
  loadChartData?: (fragmentTypes?: string[], navIds?: string[], chartId?: string) => Promise<ChartFragmentsRS>;
  saveChartData?: (payload: object, navId?: string) => Promise<ChartFragmentRS>;
  deleteChartData?: (payload: object) => Promise<void>;
  saveMultiFragments?: (fragments?: object[]) => Promise<void>;
  saveChart?: <T>(fragments: Array<Partial<ChartFragment>>, options?: SaveOptions) => Promise<T>;
  handleSaveClick: <T>(fragments: Array<Partial<ChartFragment>>, options?: SaveOptions) => Promise<T>;
  handleDiscardClick: Function;
  onEnableDisplayRecords: Function;
  displayAuthoringData?: Function;
  resetFormSubmitted: Function;
  setSkipLinkSections?: Function;
  timeSpentData: TimeSpentPartial;
  showDeleteConfirmation(onConfirmClick: Function, message?: string): Function;
  showDeleteSuccess: VoidFunction;
  showSaveSuccess: VoidFunction;
  handleNavigationAttempt: (callback: Function) => Promise<boolean>;
  toggleFlyout: (content: JSX.Element | null) => void;
  navigateToSavedPatientCharting?: VoidFunction;
  navigateToLandingPage?: VoidFunction;
  afterSave?: Function;
  setFormFieldLabel?: Function;
  addNestedTextField?: (fieldContent: ContentItem, formFieldMap: Map<string, ChartMetaFormField>) => void;
  setDirty: Function;
  backToSourceLocation?: Function;
  locale?: Locales;
  setLastFocusableElement?: Function;
  lastFocusableElement?: number;
  sidebarProps?: Function;
  isEmbededSidebarOpen?: boolean;
  setCustomUnsavedChanges?: Function;
  intl: IntlShape;
  updateFieldValue?: (field: ChartMetaFormField, value: unknown) => void;
  patientInfo?: Patient;
}

export interface ChartActionsComponentProps {
  isLocked?: boolean;
  userRole?: string;
  onSaveClick?: Function;
  onCancelClick?: Function;
  onDisplayRecordsClick?: Function;
  saveButtonHasIcon?: boolean;
  saveButtonText?: string;
  saveButtonType?: string;
  cancelButtonText?: string;
  enableSaveButton?: boolean;
  enableDisplayRecordsButton?: boolean;
  isAuthor?: boolean;
  assessmentStatus?: string;
  isMultiStepsAuthoring?: boolean;
  isDemoEmptyEhrClinicalSetup?: boolean;
}

export interface EmbeddedChart {
  payload: ChartFragment;
  navId: string;
}
export interface GraphDateTime {
  date: string;
  dateTime: string;
}

export interface BloodGlucoseGraphItem extends GraphDateTime {
  glucose: number;
  glucoseDisplay: string;
}

export interface HeightWeightGraphItem extends GraphDateTime {
  weight: string;
  weightDisplay: string;
  abdominalGirth: string;
  abdominalGirthDisplay: string;
}

export interface IntakeOutputGraphItem extends GraphDateTime {
  intake: number;
  output: number;
  intakeDisplay: string;
  outputDisplay: string;
}

export interface LaborGraphItem extends GraphDateTime {
  baselineBpm?: number;
  cervicalDilation?: string;
  station?: string;
  baselineBpmDisplay?: string;
  cervicalDilationDisplay?: string;
  stationDisplay?: number;
  duration: number;
}

export interface IntakeOutputTotalPerDayItem {
  date: string;
  intake: number;
  output: number;
}

export interface InitialStep {
  title: string;
  completedStepLinkText: string;
  route: string;
}

export interface Step {
  id: string;
  title: string;
  completedStepLinkText: string;
  route: string;
  isActive: boolean;
  heading: string;
  showActionButtons: boolean;
}

export interface NewAssignment {
  assignmentType: string;
  title: string;
  availableDate: string;
  dueDate: string;
  assignmentGradeType: string;
  assignmentTopics: {
    vtwId: string;
    text: string;
  }[];
  assignmentGoals: {
    goal: number;
  }[];
  objectives?: string;
  instructions?: string;
  students?: number[];
  isAllStudents?: boolean;
  targetType?: AssignmentTargetType;
}
export interface AssignmentGoal {
  id: number;
  text: string;
  vtwId: string;
  goal: number;
  type: string;
  optional: boolean;
}
export interface AssignmentQuestion {
  id: number;
  questionVtwId: string;
  displayOrder: number;
}
export interface SimChartAssignment {
  assignmentGoals: AssignmentGoal[];
  assignmentGradeType: string;
  assignmentQuestions: AssignmentQuestion[];
  assignmentTopics: AssignmentTopic[];
  assignmentType: string;
  availableDate: string;
  caseStudyId: string;
  courseSectionId: number;
  dueDate: string;
  eolsAssignmentId: number;
  isbn: string;
  objectives?: string;
  students?: number[];
  targetType?: AssignmentTargetType;
  title: string;
}

export interface SavedAssignment {
  assignmentType: string;
  assignmentId: string;
}

export interface AssignmentWorkflowComponentProps {
  newAssignment: NewAssignment;
  setNewAssignment: Function;
  resetNewAssignment: Function;
  steps: Step[];
  instructor?: User;
  dueDateError: string;
  setDueDateError: Function;
  locale?: Locales;
  intl?: IntlShape;
}

export interface AssignmentTile {
  id: string;
  assignmentType: string;
  title: string;
  description: string;
  isSelected: boolean;
  isDisabled: boolean;
}

export interface GradingRadioButton {
  id: string;
  value: string;
  primaryText: string;
  helperText: string;
}

export interface AssessmentGrading {
  assessmentId: number;
  studentId: string;
  userId: string;
  firstName: string;
  lastName: string;
  status: AssessmentStatus;
  startedAt: string;
  timeSpent: string;
  feedback: boolean;
  durationTime: number;
  completedAt: string;
  gradeStatus: string;
  caseStudySubmissionSummary: { totalCorrect: number; totalQuestions: number };
}

export interface Duration {
  dateString: string;
  detail: {
    value: number;
    unit: string;
  }[];
}

export interface ChartComment {
  id: string;
  title: string;
  contents: string[];
  path: string;
  createdAt: string;
}

export interface AssignmentTopic {
  id: number;
  vtwId: string;
  text: string;
}

export interface CaseStudyStep {
  id: string;
}

export interface CaseStudyPhase {
  id: string;
  caseStudyId: string;
  title: string;
  overview?: string;
  instruction?: string;
  type: string;
  displayOrder: number;
  chartsInvolved: string[];
  steps: CaseStudyStep[];
}

export interface CaseStudySequence {
  id: number;
  phaseId: string;
  contentType: PhaseType;
  status: PhaseProgress;
  displayOrder: number;
  content: CaseStudyPhase;
  startedPhaseList?: CaseStudyPhase[];
  caseStudyOverview: CaseStudyPhase;
  patient: { id: number; firstName: string; lastName: string; avatarUrl: string; hospitalFloor: string; age: number; gender: string };
  totalPhases: number;
}

export interface CaseStudyProduct {
  isbn: string;
  name: string;
}

export interface CaseStudy {
  id: string;
  title: string;
  diagnosis: string;
  concept: string;
  sherpathModule: string;
  patient: { id: number; firstName: string; lastName: string; avatarUrl: string; hospitalFloor: string; age: number; gender: string };
  totalTimeLimit: number;
  learningObjective: string;
  phases: CaseStudyPhase[];
  isComingSoon: boolean;
  product?: CaseStudyProduct;
}

export interface AnswerSubmission {
  questionType: string;
  vtwId: string;
  prompt: string;
  textReference: string[];
  correctResponse: string[];
  responseChoices: { [x: string]: string };
  feedback: { [x: string]: string };
  selectedAnswers: string[];
  correct: boolean;
}

export interface CaseStudySubmissionSummary {
  assignmentType: string;
  assignmentGradeType: string;
  assessmentId: string;
  sequenceChunkSummaries: CaseStudySequenceChunkSummary[];
  totalCorrect: number;
  totalQuestions: number;
}

export interface CaseStudySequenceChunkSummary {
  stepChunks: CaseStudySequenceChunk[];
  status?: PhaseProgress;
}

export interface CaseStudySequenceChunk {
  content: AnswerSubmission;
  correct: boolean;
  selectedAnswers: string[];
}

export interface Score {
  label: string;
  value: string;
}
export interface ScaleContent {
  formFieldId: string;
  label: string;
  score?: Score;
}

export interface PatientContext {
  hospitalFloors: string[];
  gender: string;
  ageInDays: number;
  assignmentType: AssignmentType;
  isAuthor: boolean;
  locale: Locales;
}

export interface DosageTime {
  id: string;
  dosageDate: string;
  dosageTime: string;
  administeredDate: string;
  administeredTime?: string;
  administeredTimeOffset?: string;
  comments?: string;
  firstInitial?: string;
  lastInitial?: string;
  isAdministered?: boolean;
}

export interface MarRecord {
  fragmentId: string;
  drugName: string;
  orderStart?: string;
  orderStartDate?: string;
  orderStartTime?: string;
  orderType?: string;
  orderStop?: string;
  dose?: string;
  route?: string;
  frequency?: string;
  action?: string;
  status?: string;
  specialInstructions?: string;
  dosageTimes?: DosageTime[];
  originDosageTimes?: DosageTime[];
  holdDate?: string;
  holdTime?: string;
  holdComments?: string;
  holdFirstInitial?: string;
  holdLastInitial?: string;
  isHold?: boolean;
  discontinueDate?: string;
  discontinueTime?: string;
  discontinueComments?: string;
  discontinueFirstInitial?: string;
  discontinueLastInitial?: string;
  isDiscontinued?: boolean;
}

export interface MarDosageRecord extends MarRecord, DosageTime {}

export interface Author {
  id: number;
  firstName: string;
  lastName: string;
  credential: string;
  role: string;
  initials: string;
}

export interface PatientCardCategory {
  id: string;
  value: string;
  name: string;
  label: string;
  formFieldId: string;
}

export interface SkipLinkSection {
  section: string;
  title: string;
}

export interface ScaleRollUpContent {
  content: ScaleRollUpContentItem[];
  total: number;
  riskLevel: FallRiskLevel;
}

export interface ScaleRollUpContentItem {
  label: string;
  range: number[];
}

export interface GroupScaleQuestion {
  label: string;
  formFields: string[];
  hasSectionDivider?: boolean;
}

export interface CourseEntitlement {
  bundleMemberProduct: [];
  components: [];
  isbn: string;
  productTypeKey: string;
  productTypeName: string;
  title: string;
  type: string;
}

export interface TimeSpent {
  assignmentId: number;
  assessmentId: number;
  userId: number;
  eventTime: string;
  phaseIndex: number;
  currentLocation: LocationData;
  eventType: EventType;
}

// Used to allow for changing eventType & eventTime
// Event time will be set automatically by recordTimeSpent helper function
// Event type will be manually sent as a prop when recordTimeSpent helper function is called
// Note: All props will be required in final sendTimeSpent api call
export interface TimeSpentPartial {
  assignmentId: number;
  assessmentId: number;
  userId: number;
  eventTime?: string;
  phaseIndex: number;
  currentLocation: LocationData;
  eventType?: EventType;
}

export interface LocationData {
  id: string;
  type: LocationType;
}

export interface TimeSpentState {
  assignmentTimeSpentList: TimeSpentRS[];
  overallAssignmentTime?: number;
  chartTime?: number;
}

export interface ClinicalKeyLink {
  name: string;
  url: string;
}

export interface FilterConfig {
  searchName: string;
  status: AssessmentStatus;
  currentPage: number;
}

export interface CreateChartMetaFormFieldParams {
  disabled?: boolean;
  label?: string;
  name: string;
  onChangePreValidation?(chartField: ChartMetaFormField, formFieldMap: Map<string, ChartMetaFormField>);
  onChangePostValidation?(chartField: ChartMetaFormField, formFieldMap: Map<string, ChartMetaFormField>);
  onGroupChange?: (chartField: ChartMetaFormField) => ChartMetaFormField[];
  type: FormFieldInputType;
  value?: string | string[];
  contentIds?: string[];
  scaleContent?: ScaleContent[];
  errorLabel?: string;
  parentLabel?: string;
  fieldIdsToClearValue?: string[];
  defaultValue?: string | string[];
  selected?: boolean;
  labelMultilineTooltip?: JSX.Element;
}

export type ChartMetaFormFieldParams = CreateChartMetaFormFieldParams & {
  isHidden?: boolean;
};
