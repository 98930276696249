import cx from 'classnames';
import { MultiSectionsFragment } from 'models/api-response';
import { AppMessage, Locales } from 'constants/app.constant';
import { formatDate, toMomentWithParsers } from 'helpers/datetime.helper';
import { Badge, Box, ButtonLinkIcon, GradingChart, HorizontalScrollableContainer, Sidebar } from 'components/common';
import { ELSButton, ELSDataTable } from 'components/common/els';
import 'assets/project/components/common/chart.scss';
import EditChart from './EditChart';
import { TableItem } from './SavedPatientCharting';
import './saved.patient.charting.scss';

export interface SavedPatientChartingViewProps {
  chartTitle: string;
  children?: Function;
  className?: string;
  buildStatusBadge?: Function;
  data: TableItem[];
  hideDelete?: boolean;
  isShowGrading: boolean;
  onAfterSideBarClose: Function;
  onCloseSideBar: Function;
  onDeleteClick: Function;
  onViewClick: Function;
  openSideBar: boolean;
  selectedFragment: MultiSectionsFragment;
  linkedFragments?: MultiSectionsFragment[];
  selectedRecordChartPathName: string;
  simChartId: string;
  locale?: Locales;
  isInProgressGradingEnabled?: boolean;
}

export const defaultCustomRender = (field) => (row) => <span className={cx({ 'row--deleted': !row.active })}>{row[field]}</span>;

const SavedPatientChartingView = (props: SavedPatientChartingViewProps) => {
  const { className, onViewClick, onDeleteClick } = props;
  // ELSDataTable requires children must be an array
  const renderedChildren = props.children ? props.children({ onViewClick, onDeleteClick, defaultCustomRender }) : null;

  const actionsRenderer = (row) => {
    return (
      row.active && (
        <div className={cx({ 'u-cursor-pointer': row.active })}>
          <ButtonLinkIcon className="o-els-icon-svg--sub" iconName="enlarge" iconSize="1x" text="View" onClick={() => onViewClick(row)} />
        </div>
      )
    );
  };

  const chartRenderer = (row) => <ELSButton type="link" text={row.chart} onClick={() => onViewClick(row)} />;

  const entryTimeRenderer = (row) => (
    <Badge className={cx('c-els-badge--inline', { 'row--deleted': !row.active })}>
      {formatDate({ date: toMomentWithParsers(row.entryTime).toDate(), locale: props.locale, includeTime: true })}
    </Badge>
  );

  return (
    <div className={cx('saved-patient-charting', className)}>
      {props.chartTitle && <h1>{props.chartTitle}</h1>}
      <Box mt2 mb className="saved-patient-charting__table">
        <HorizontalScrollableContainer>
          <ELSDataTable data={props.data}>
            {renderedChildren || [
              <column key="chart" field="chart" header="Chart" sortable customRender={chartRenderer} />,
              <column key="chartSection" field="chartSection" header="Chart Section" />,
              <column key="entryTime" field="entryTime" header="Entry Time" sortable customRender={entryTimeRenderer} />,
              <column key="enteredBy" field="enteredBy" header="Entered By" customRender={defaultCustomRender('enteredBy')} />,
              <column key="actions" header="Actions" customRender={actionsRenderer} />
            ]}
          </ELSDataTable>
        </HorizontalScrollableContainer>
        {props.data.length === 0 && (
          <Box my>
            <p>{AppMessage.NO_TABLE_DATA}</p>
          </Box>
        )}
      </Box>
      <Sidebar
        open={props.openSideBar}
        onClose={() => props.onCloseSideBar(true)}
        onAfterClose={props.onAfterSideBarClose}
        stickyElement={props.isShowGrading ? <GradingChart selectedFragment={props.selectedFragment} selectedRecordChartPathName={props.selectedRecordChartPathName} isInProgressGradingEnabled={props.isInProgressGradingEnabled} /> : null}
      >
        {props.selectedFragment && (
          <EditChart
            simChartId={props.simChartId}
            selectedFragment={props.selectedFragment}
            linkedFragmentRecords={props.linkedFragments}
            onCloseClick={(hasDataChanged) => props.onCloseSideBar(hasDataChanged)}
            hideDelete={props.hideDelete}
            buildStatusBadge={props.buildStatusBadge}
          />
        )}
      </Sidebar>
    </div>
  );
};

SavedPatientChartingView.displayName = 'SavedPatientChartingView';
export default SavedPatientChartingView;
